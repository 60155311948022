@import 'src/assets/styles/variables.module.scss';

.main {
    background: $neutralDark;
    border-radius: 1em;
    height: 64px;
    width: 64px;
    box-shadow: inset -3px -3px 7px rgba(255, 255, 255, 0.4),
        inset 3px 3px 7px rgba(136, 165, 191, 0.48);
    display: flex;
    align-items: center;
    overflow: hidden;
    justify-content: center;
    img {
        max-width: 64px;
        max-height: 64px;
    }
}
