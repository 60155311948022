@import 'src/assets/styles/variables.module.scss';
@import 'src/assets/styles/fontMixins.scss';

.main {
    max-width: 960px;
    margin-bottom: 1em;
    display: grid;
    grid-template-columns: 30px 1fr;

    &:last-child {
        .mapStreet {
            display: none;
        }
    }
}

.container {
    padding: 17px;
    position: relative;
    background: $background;
    box-shadow: $boxShadowPrimary, -10px -10px 30px $neutralLight;
    border-radius: 1em;
    flex: 1;

    &:hover {
        background: $neutralLight;

        .corner {
            g {
                fill: $neutralLight;
            }
        }
    }
}

.content {
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: 18% minmax(32%, 370px) 27% 14%;

    @media (max-width: 800px) {
        grid-template-columns: 1fr;
        grid-template-rows: 18fr 20fr 27fr 14fr;
    }
}

.map {
    position: relative;
    display: flex;
    width: 30px;
    height: 100%;
    align-items: center;
}

.mapCircle {
    background: $neutralLight;
    border: 3px solid $primary3;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    z-index: 2;
}

.mapStreet {
    z-index: 2;
    position: absolute;
    width: 3px;
    background: $primary3;
    height: calc(100% + 13px);
    left: 0;
    top: 50%;
    transform: translate(5px, 6px);
}

.corner {
    position: absolute;
    display: flex;
    left: -22px;
    top: 50%;
    transform: rotate(90deg) translate(0, -50%);
    z-index: 2;

    g {
        fill: $background;
    }
}

.timeAndLength {
    padding-right: 20px;
    border-right: 1px solid rgba(30, 52, 102, 0.1);
    display: flex;
    justify-content: space-between;

    @media (max-width: 800px) {
        border-right: none;
    }
}

.length {
    width: 68px;
    background: $exportButtonGradient;
    border-radius: 1em;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .value {
        color: $textColor2;
        @include text-24-bold;
    }

    .mill {
        color: $textColor2;
        @include text-13-medium;
    }
}

.time {
    width: 68px;
    background: $neutralLight;
    border-radius: 1em;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .value {
        color: $textColor;
        @include text-15-medium;
    }
}

.nameContainer {
    padding-left: 25px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: -20px;

    @media (max-width: 800px) {
        padding-left: 0;
        width: 100%;
        overflow: hidden;
        padding-top: 25px;
    }

    .title {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        color: $textColor;
        @include text-15-medium;

        @media (max-width: 800px) {
            max-width: 300px;
            padding-right: 20px;
            display: block;
        }
    }
}

.name {
    color: $grey;
    @include text-13-regular;
    margin-right: 15px;
}

.remainingContainer {
    display: flex;
    align-items: center;

    @media (max-width: 800px) {
        padding-left: 0;
        justify-content: space-between;
    }

    .name {
        color: $grey;
        text-transform: uppercase;
        @include text-13-regular;
        text-align: right;
    }
}

.chart {
    width: 90px;
    position: relative;
    height: 60px;
}

.tripChart {
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
}

.remainingBatteryValue {
    color: $textColor;
    @include text-17-medium;
}

.percent {
    color: $textColor;
}

.circleChildren {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    margin-bottom: 5px;
}

.buttonContainer {
    display: flex;
    justify-content: space-evenly;

    .map {
        display: flex;
        align-items: center;
        cursor: pointer;
    }

    .remove {
        display: flex;
        align-items: center;
        cursor: pointer;
    }
}
