@import 'src/assets/styles/fontMixins.scss';
@import 'src/assets/styles/variables.module.scss';
@import 'src/assets/styles/signTemplate.scss';

.backGround {
    position: fixed;
    z-index: 1300;
    right: 0px;
    bottom: 0px;
    top: 0px;
    left: 0px;
    align-items: center;
    justify-content: center;
    background-color: $modalBackground;
    display: flex;
}

.content {
    display: flex;
    flex-direction: column;
    box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2),
        0px 24px 38px 3px rgba(0, 0, 0, 0.14),
        0px 9px 46px 8px rgba(0, 0, 0, 0.12);
    border-radius: 4px;
    overflow: hidden;
    background: $neutralLight;
    max-width: 500px;

    @media (max-width: 800px) {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        max-width: 100%;
        max-height: 100%;
        width: 100%;
    }
}

.page {
    @include page;
}

.container {
    @include container;
}

.section {
    @include section;
}

.header {
    margin-bottom: 15px;

    @media (max-height: 600px) {
        margin-bottom: 5px;
    }

    h3 {
        @include text-24-bold;
        color: $textColor;
        text-align: center;
        padding: 0;
        margin: 0;
    }
}

.br {
    display: block;
    margin-bottom: 5px;
}

.textContainer {
    margin-bottom: 50px;
}

.text {
    @include text;
    color: $textColor;
}

.link {
    @include link;
    background: none;
}

.footer {
    @include footer;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 5px;
    padding-top: 10px;
}

.radio {
    display: grid;
    grid-template-columns: min-content 1fr;
    grid-gap: 15px;
    align-items: center;
    margin-bottom: 10px;

    .text {
        @include text;
        color: $textColor;
    }
}

.alertContainer {
    width: 100%;
    position: relative;

    .wrapper {
        position: absolute;
        width: 100%;
        top: -45px;
    }
}

.hidden {
    visibility: hidden;
}

.confirmText {
    margin-top: 60px;
}

.textContainerConfirm {
    margin-bottom: 0px;
}
