.main {
    position: absolute;
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.logo {
    padding: 48px;
    height: 9.5rem;
}

// responsive

@media only screen and (max-width: 800px) {
    .main {
        position: initial;
    }
}

@media only screen and (max-width: 800px) {
    .main {
        justify-content: center;
    }

    .closeButton {
        position: absolute;
        right: 0;
        top: 0;
    }

    .logo {
        padding: 28px;
        height: 5.5rem;
        padding-bottom: 0;
    }
}