@import 'src/assets/styles/variables.module.scss';
@import 'src/assets/styles/fontMixins.scss';

@mixin container {
    height: 100%;
    padding: 20px 40px;
    display: flex;
}

@mixin section {
    margin: auto;
    max-width: fit-content;
    max-height: 560px;
    display: grid;
    height: 100%;
    width: 100%;
    grid-template-rows: min-content 1fr min-content;
}

@mixin titleContainer {
    margin-bottom: 13px;
}

@mixin footerText {
    padding: 0;
    margin: 0;
    margin-bottom: 10px;
    color: $neutralLight;
    @include text-15-regular;
}

@mixin text {
    padding: 0;
    margin: 0;
    color: $neutralLight;
    @include text-17-regular;
}

@mixin submitContainer {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

@mixin submitButton {
    margin-bottom: 10px;
}

@mixin footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 85px;
}

@mixin page() {
    min-height: 100%;
    display: grid;
}

@mixin ionic-content($background: $backgroundGradient) {
    --background: #{$background};
}

@mixin link {
    color: $textColor2;
    width: 224px;
    padding: 9px;
    border: 1px solid $textColor2;
    border-radius: 16px;
    @include text-15-medium;
    display: block;
    text-align: center;
    text-decoration: none;
}
