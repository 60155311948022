@import 'src/assets/styles/variables.module.scss';
@import 'src/assets/styles/mixins.scss';

.list {
    @include list;

    li {
        &:first-child {
            margin-bottom: 15px;
        }
    }
}

.listItem {
    padding: 6px 0;
    display: grid;
    grid-template-columns: 2fr 1fr;
    align-items: center;
    max-height: 30px;

    .valTitle {
        @include text-17-regular;
        color: grey;
        text-transform: uppercase;
    }

    .value {
        color: $textColor;
        @include text-20-bold;
    }
}

.flexItem,
.advantages {
    flex: 1;
}

.flexItem {
    .icon {
        display: flex;
        align-items: center;
        justify-content: center;

        img {
            max-width: 150px;
        }
    }
}

.tariffName {
    p {
        text-align: center;
        color: $textColor;
        margin: 0;
        padding: 0;
        @include text-17-medium;
    }
}

.main {
    text-decoration: none;
    display: flex;
    flex-direction: column;
    background: $background2;
    box-shadow: $dropDownBoxShadow;
    border-radius: 1em;
    max-width: 340px;
    margin: 0 auto;
    padding: 36px;
    color: $textColor;
}

.content {
    flex: 1;
    display: flex;
    flex-direction: column;
}

.title {
    h3 {
        margin: 0;
        color: $textColor;
        @include text-17-regular;
        text-align: center;
    }
}
