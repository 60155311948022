@import 'src/assets/styles/mixins.scss';
@import 'src/assets/styles/variables.module.scss';

.main {
    position: fixed;
    max-width: 370px;
    @media (max-width: $mobile) {
        width: 75%;
        max-width: 75%;
        right: 0 !important;
        left: 0 !important;
        bottom: 0 !important;
        top: 150px;
        margin-left: auto;
        margin-right: auto;
    }
}

.message {
    border-radius: 1em;
    box-shadow: 0px 4px 32px 2px $transparentBlack;
    margin-left: auto;
    margin-right: auto;
    @media (max-width: $mobile) {
        border-radius: 20px;
        padding: 0px;
    }
}

.contentContainer {
    display: flex;
    align-items: center;
}

.button {
    @include purpleButton;
}