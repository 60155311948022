@import 'src/assets/styles/mixins.scss';
@import 'src/assets/styles/fontMixins.scss';

.tableContainer {
    overflow: auto;
    height: 100%;
}

.table {
    display: grid;
    width: 100%;
    height: 100%;
    grid-template-rows: min-content minmax(200px, 100%);
}

.thead {
    padding: 0px 30px;
    padding-top: 20px;

    @media (max-width: 600px) {
        padding: 0px 5px;
        padding-top: 20px;
    }
}

.trHead {
    display: grid;
    background: $background;
    box-shadow: $boxShadowPrimary, -5px -5px 20px $neutralLight;
    border-radius: 1em;
    padding: 17px 20px;
    user-select: none;

    .th {
        display: flex;
        align-items: center;
        padding: 0 10px;
        @include text-17-regular;
        color: $tableColor;
    }
}

.tbody {
    padding: 0px 30px;
    padding-top: 8px;
    padding-bottom: 20px;
    overflow: auto;
    overflow-y: overlay;
    @include scroll;

    @media (max-width: 600px) {
        padding: 0px 5px;
        padding-top: 8px;
    }
}

.trBody {
    display: grid;
    background: $neutralLight;
    box-shadow: $boxShadowPrimary;
    border-radius: 1em;
    padding: 17px 20px;
    margin-bottom: 8px;

    .td {
        display: flex;
        align-items: center;
        padding: 0 10px;
        @include text-17-regular;
        color: $tableColor;
    }
}

.sortContainer {
    .arrowTop {
        transform: rotate(180deg);
    }
}
