@import 'src/assets/styles/variables.module.scss';
@import 'src/assets/styles/fontMixins.scss';

.main {
    border-radius: 1em;
    background: $background2;
    padding: 36px;
    height: 330px;
    max-width: 340px;
    margin: 26px auto 0 auto;
    position: relative;
}

.check {
    position: absolute;
    background: $secondaryGradient;
    border-radius: 1em;
    height: 64px;
    width: 64px;
    top: 0;
    right: 0;
    transform: translate(40%, -40%);
    display: flex;
    img {
        margin: auto;
        height: 20px;
    }
}

.grid {
    height: 100%;
    display: grid;
    grid-template-rows: repeat(5, 1fr);
    grid-template-columns: 2fr 1fr;
    grid-template-areas:
        'car car'
        'car car'
        'model modelValue'
        'battery batteryValue'
        'size sizeValue';
}

.car {
    grid-area: car;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    span {
        @include text-20-bold;
        color: $textColor;
    }

    img {
        max-width: 100%;
        max-height: 100%;
    }
}

.valueTitle {
    display: flex;
    align-items: center;
    @include text-15-regular;
    color: grey;
}

.value {
    display: flex;
    align-items: center;
    color: $textColor;
   @include text-17-medium;
}
