@import 'src/assets/styles/variables.module.scss';
@import 'src/assets/styles/fontMixins.scss';

.main {
    padding: 12px;
    color: $textColor;
    font-weight: 600;
    font-size: 14px;
    line-height: 140%;
    letter-spacing: 0.005em;
    width: 100%;
    cursor: pointer;
    margin-bottom: 0.2rem;

    overflow: hidden;
    text-overflow: ellipsis;

    &:hover {
        border-radius: 14px;
        background: #e9ebfa;
    }
    span {
        font-weight: 600;
    }
}

.selected {
    border-radius: 14px;
    background: #e9ebfa;
}

.activeItem {
    // background: rgba(255, 255, 255, 0.6);
}
