@import 'src/assets/styles/variables.module.scss';
@import 'src/assets/styles/fontMixins.scss';

.input {
    border-radius: 1em;
    width: 100%;
    height: 56px;
    @include text-17-regular;
    color: $textColor;
    background: $neutralDark;
    padding: 20px;
    border-radius: 1em;
    border: none;
    box-shadow: $boxShadowPrimary2;
    -webkit-appearance: none;

    --padding-top: 20px;
    --padding-end: 20px;
    --padding-bottom: 20px;
    --padding-start: 20px;
    --border-radius: 1em;
}

.input:disabled {
    -webkit-appearance: none;
    border-radius: 1em;
    width: 100%;
    height: 56px;
    @include text-17-regular;
    color: $neutralMedium;
    background: $neutralDark;
    padding: 20px;
    border-radius: 1em;
    border: none;
    box-shadow: $boxShadowPrimary2;
    opacity: 1;
    -webkit-text-fill-color: $neutralMedium;
}
