$font: 'Montserrat';
$greenColor: #009f2d;
$fontSize : 16px;

$errorColor: #f75161;
$primaryRed: #eb5757;

$grey: rgba(30, 52, 102, 0.5);
$lightGrey: #d0d0d1;
$white: #FFFFFF;

$tablet: 770px;
$mobile: 425px;

$primaryBlue : #4A5BF0;
$textColor: #0f1052;
$textColor2: #fbfbfb;
$textColor3: #009f2d;
$textColor4: #000000;
$textColorGrey: #abbecf;
$activeButtonColor: #4048ef;
$diabledColor: #E5E6E6;
$diabledBackgroundColor: #FAFAFA;

$background: #f0f0f3;
$background2: #edf3fa;
$background3: #FFFFFF;

$primary: #2087f2;
$primary2: #001c51;
$primary3: #1e3466;

$secondary: #17c16e;
$secondary2: #1dd6a8;
$secondary3: #22ffc8;

$tertiary: #645aff;
$tertiary2: #a573ff;

$navLinkTitle: #caadff;

$neutralDark: #e3edf7;
$neutralLight: #fbfbfb;

$primaryGray: #d0d0d1;
$placeHolder: #777575;
$neutralMedium: #81808e;

$primaryGradient: linear-gradient(
    303.22deg,
    #4048ef -10.43%,
    #192575 77.13%,
    #5a7bef 154.4%
);

// $primaryGradient1: linear-gradient(345.87deg, #4048ef 0%, #5a7bef 100%);
$primaryGradient1: linear-gradient(345.87deg, #4048ef 0%, #5a7bef 100%);
$secondaryGradient: linear-gradient(86.88deg, #17c16e 1.89%, #1dd6a8 97.61%);
$tertiaryGradient: linear-gradient(314.33deg, #645aff -3.69%, #a573ff 109.82%);
$backgroundGradient: linear-gradient(0deg, #4a5aed 0%, #935eff 100%);
$boxShadow: -10px -10px 30px #ffffff, 10px 10px 30px rgba(174, 174, 192, 0.4);
$boxShadowInvert: 10px 10px 30px rgba(174, 174, 192, 0.4),
    -10px -10px 30px #ffffff;

$boxShadowPrimary: 10px 10px 30px rgba(174, 174, 192, 0.4);
$boxShadowPrimary2: 0px 4px 10px rgba(0, 0, 0, 0.15);

$dropDownBoxShadow: 0px 20px 20px rgba(182, 202, 222, 0.5);

$boxShadow2: 0px 20px 60px #b6cade;
$boxShadow3: 0px 20px 20px rgba(0, 0, 0, 0.2);
$chartColor: #41c9b9;
$chartColor2: #9c7dfb;

$addButtonGradient: linear-gradient(349.57deg, #645aff 35.21%, #a573ff 131.36%);
$toggleGradient: linear-gradient(87.89deg, #ffffff 3.58%, #b2c3d6 305.99%);

$defaultButtonGradient: linear-gradient(
    75.31deg,
    #009F2D 1.89%,
    #009F2D 97.61%
);

$disabledButtonGradient: linear-gradient(
    75.31deg,
    #D0D0D1 1.89%,
    #D0D0D1 97.61%
);


$termsAndPolicyTextColor: #bbd6f4;

$exportButtonGradient: linear-gradient(
    315.97deg,
    #645aff -3.69%,
    #a573ff 109.82%
);

$selectButtonBorder: 1px solid #b7cce1;

$oopsBackground: #e8e4e1;
$oopsColor: #e8e4e1;

$hoverShadow: 0px 20px 20px rgba(0, 0, 0, 0.1);

$tableColor: rgba(30, 52, 102, 0.8);

$modalBackground: rgba(0, 0, 0, 0.5);
$transparentBlack: rgba(0, 0, 0, 0.1);
$backgroundHover: rgba(30, 52, 102, 0.04);

:export {
    background: $background;
    background3: $background3;
    secondary: $secondary;
    tertiary: $tertiary;
    tertiary2: $tertiary2;
    neutralLight: $neutralLight;
    secondaryGradient: $secondaryGradient;
    primaryGradient1: $primaryGradient1;
    primaryBlue: $primaryBlue;
    primaryRed: $primaryRed;
    lightGrey: $lightGrey;
    errorColor: $errorColor;
    primary3: $primary3;
    textColor: $textColor;
    chartColor: $chartColor;
    chartColor2: $chartColor2;
    font: 'Montserrat';
    greenColor: $greenColor;
    background2: $background2;
    diabledColor: $diabledColor;
    diabledBackgroundColor: $diabledBackgroundColor;
    white: $white;
}
