@import 'src/assets/styles/variables.module.scss';
@import 'src/assets/styles/mixins.scss';
@import 'src/assets/styles/fontMixins.scss';

.SignUp {
    flex: 1 1;
    position: relative;
    display: flex;
    height: 100%;

    @media (max-width: $tablet) {
        flex-direction: column;
    }
}



.advantagesTitle {
    @include text-24-bold;
    color: $textColor;

    margin: 25px 0;

    @media (max-width: 1024px) {
        margin: 17px 0;
    }

    @media (max-width: $tablet) {
        display: none;
    }
}

.advantagesQuestion {
    @include text;

    @media (max-width: 1024px) {
        margin: 17px 0;
    }

    @media (max-width: $tablet) {
        display: none;
    }
}

.list {
    @include text;
    @include list;

    @media (max-width: $tablet) {
        display: none;
    }
}

.listItem {
    @include list-item;
}

.advantagesText {
    @include text;

    @media (max-width: $tablet) {
        display: none;
    }
}

.stepsContainer {
    padding-top: 4rem;

    @media (max-width: $tablet) {
        display: none;
    }
}

.gridSignUp {
    flex: 1;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;

    @media (max-width: $tablet) {
        grid-template-columns: 1fr;
        grid-template-rows: 1fr;
    }
}

.envSelect{
    position: fixed;
    top: 0;
    width: 70px;
    height: 70px;
}
.envSelect::part(icon) {
    opacity: 0;
  }
  .hiddenTap{ 
    position: fixed;
    top: 0;
    z-index: 9;
    width: 70px;
    height: 70px;
  }