@import 'src/assets/styles/variables.module.scss';
@import 'src/assets/styles/fontMixins.scss';

.popupContainer {
    position: absolute;
    box-sizing: border-box;
    box-shadow: 0px 1px 5px rgba(23, 24, 25, 0.59692);
    border-radius: 1em;
    background: $neutralLight;
    width: 177px;
    left: 50%;
    transform: translate(-50%, 0);
    display: flex;
    flex-direction: column;
    z-index: 1000;
}

.popupTitle {
    h3 {
        color: $textColor;
        overflow: hidden;
        text-overflow: ellipsis;
        padding: 0 10px;
        @include text-17-medium;
    }
}

.popupDescription {
    background: $background;
    border-radius: 1em;
    flex: 1;
}

.popupDescription {
    .title {
        h3 {
            color: $textColor;
            @include text-13-regular;
            text-align: center;
            opacity: 0.8;
            overflow: hidden;
            text-overflow: ellipsis;
            padding: 0 10px;
        }
    }

    .text {
        padding: 0 0.75em;

        a {
            color: $grey;
            @include text-13-medium;
            text-decoration: none;
            display: block;
            padding: 10px 0;

            &:hover {
                text-decoration: underline;
            }
        }
    }
}
