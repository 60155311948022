@import 'src/assets/styles/variables.module.scss';
@import 'src/assets/styles/fontMixins.scss';

.main {
    position: relative;
    width: 100%;

    @media (max-width: $mobile) {
        padding-bottom: 0;
    }
}

.cornerContainer {
    position: absolute;
    display: flex;

    g {
        fill: var(--cornerColor);
    }
}

.content {
    background: var(--color);
    padding: 15px;
    border-radius: 4px;
    color: $textColor;
    @include text-13-regular;
    box-shadow: 0px 1px 17px 6px rgba(0, 0, 0, 0.59692);
}

.cornerCenter {
    left: 50%;
    transform: translateX(-50%);
}

.rightNotificationCorner {
    right: 0;
    transform: translate(-13px);
}

.cornerRight {
    right: 0;
    transform: translate(-2px);
}

.cornerBottom {
    left: 50%;
    transform: translate(-50%) rotate(180deg);
    top: -6px;
}

.cornerBottomRight {
    transform: translate(-7%) rotate(180deg);
    top: -6px;
    right: 0;
}

.cornerBottomLeft {
    transform: translate(-7%) rotate(180deg);
    top: -6px;
    left: 0;
}
