@import 'src/assets/styles/variables.module.scss';
@import 'src/assets/styles/fontMixins.scss';

.button {
    border: none;
    background: none;
    color: $textColor;
    cursor: pointer;
    @include text-17-medium;
}
