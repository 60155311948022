@import "src/assets/styles/variables.module.scss";

.button {
    border: none;
    background: none;
    height: 30px;
    width: 30px;
    margin: 25px;
    cursor: pointer;
}
