.alertContainer {
    position: absolute;
    width: 100%;
    transform: translateY(calc(-100% - 3px));
}
.container {
    width: 100%;
    position: relative;
    padding-top: 50px;
}
