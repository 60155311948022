@import 'src/assets/styles/variables.module.scss';
@import 'src/assets/styles/fontMixins.scss';

.information {
    padding: 0;
    margin: 0;

    span {
        @include text-15-medium;
    }
}

.readMoreLink {
    text-decoration: none;
    @include text-15-medium;
    color: $greenColor;
}
