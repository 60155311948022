@import 'src/assets/styles/variables.module.scss';
@import 'src/assets/styles/fontMixins.scss';

.main {
    width: 100%;
    background: $oopsBackground;
    padding: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.image {
    width: 20%;
    max-width: 150px;

    img {
        width: 100%;
    }
}

.text {
    text-align: center;
    color: $oopsColor;

    h3 {
        @include text-20-bold;
        margin: 20px 0;
    }

    p {
        @include text-17-medium;
        margin: 20px 0;
    }
}

.error {
    white-space: pre-wrap;
    color: $textColor4;
    width: 100%;
}

.backLink {
    margin: 10px 0;
    @include text-20-bold;
    color: $oopsColor;

    a {
        color: $oopsColor;
    }
}
