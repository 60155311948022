@import 'src/assets/styles/variables.module.scss';
@import 'src/assets/styles/fontMixins.scss';

.main {
    width: 100%;
    @media (max-width: 800px) {
        position: fixed;
        z-index: 10;
        bottom: 0;
    }
}

.container {
    padding: 17px;
    position: relative;
    background: $neutralLight;
    box-shadow: $boxShadowPrimary, -10px -10px 30px $neutralLight;
    border-radius: 1em;
    flex: 1;
}

.content {
    display: grid;
    grid-template-rows: 1fr;
    // grid-template-columns: 18% minmax(32%, 200px) 36% 14%;
    grid-template-columns: 12% minmax(32%, 32vw) 36%;

    @media (max-width: 800px) {
        grid-template-columns: 1fr;
        grid-template-rows: 18fr 20fr 27fr;
    }
}

.map {
    position: relative;
    display: flex;
    width: 30px;
    height: 100%;
    align-items: center;
}

.mapCircle {
    background: $neutralLight;
    border: 3px solid $primary3;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    z-index: 2;
}

.mapStreet {
    z-index: 2;
    position: absolute;
    width: 3px;
    background: $primary3;
    height: calc(100% + 13px);
    left: 0;
    top: 50%;
    transform: translate(5px, 6px);
}

.corner {
    position: absolute;
    display: flex;
    left: -22px;
    top: 50%;
    transform: rotate(90deg) translate(0, -50%);
    z-index: 2;
    g {
        fill: $background;
    }
}

.timeAndLength {
    padding-right: 20px;
    border-right: 1px solid rgba(30, 52, 102, 0.1);
    display: flex;
    justify-content: space-between;
    @media (max-width: 1100px) {
        padding-right: 0px;
    }
    @media (max-width: 800px) {
        border-right: none;
    }
}

.length {
    width: 68px;
    background: $exportButtonGradient;
    border-radius: 1em;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @media (max-width: 800px) {
        flex-direction: row;
    }
    .value {
        color: $textColor2;
        @include text-20-bold;
    }

    .mill {
        color: $textColor2;
        @include text-13-medium;
    }
}

.circleTrip {
    @media (max-width: 800px) {
        display: none;
    }
}

.progressMobile {
    display: none;
    @media (max-width: 800px) {
        display: block;
    }
}

.time {
    width: 68px;
    background: $neutralLight;
    border-radius: 1em;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @media (max-width: 800px) {
        flex-direction: row;
    }
    .value {
        color: $textColor;
        @include text-15-medium;
    }
}

.nameContainer {
    display: flex;
    align-items: center;
    padding-left: 25px;
    @media (max-width: 1100px) {
        padding-left: 5px;
    }
    @media (max-width: 800px) {
        padding-left: 0;
        justify-content: space-between;
    }
    .title {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        color: $textColor;
        @include text-15-medium;
    }
}

.timeImg {
    display: flex;
    align-items: center;
}

.name {
    color: $grey;
    text-transform: uppercase;
    margin-right: 15px;
    @include text-13-regular;
}

.remainingContainer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    @media (max-width: 800px) {
        padding-left: 0;
        justify-content: space-between;
    }
    .name {
        color: $grey;
        text-transform: uppercase;
        @include text-13-regular;
        text-align: right;
    }
}

.chart {
    width: 54px;
}

.remainingBatteryValue {
    color: $textColor;
    @include text-17-medium;
}

.percent {
    color: $textColor;
}

.circleChildren {
    display: flex;
    align-items: flex-end;
    justify-content: center;
}

.buttonContainer {
    display: flex;
    justify-content: space-evenly;
    .map {
        display: flex;
        align-items: center;
        cursor: pointer;
    }
    .remove {
        display: flex;
        align-items: center;
        cursor: pointer;
    }
}
