@import 'src/assets/styles/variables.module.scss';
@import 'src/assets/styles/fontMixins.scss';

.container {
    position: relative;
    width: 100%;
}

.overlay {
    position: absolute;
    border-radius: 1em;
    width: 100%;
    height: 56px;
    @include text-17-regular;
    color: $textColor;
    font-weight: 600;
    display: flex;
    padding: 20px;
    white-space: pre-wrap;
    align-items: center;
    top: 0;
    left: 0;
    pointer-events: none;
    .value {
        opacity: 0;
    }
}
.overlayAlignEnd {
    justify-content: flex-end;
}

.input {
    border-radius: 1em;
    width: 100%;
    height: 56px;
    @include text-17-regular;
    color: $textColor;
    background: #FBFBFB;
    font-weight: 600;
    padding: 20px;
    border-radius: 1em;
    border: none;
    box-shadow: $boxShadowPrimary2;
    -webkit-appearance: none;
}

.input:disabled {
    -webkit-appearance: none;
    border-radius: 1em;
    width: 100%;
    height: 56px;
    @include text-17-regular;
    color: $neutralMedium;
    background: $neutralDark;
    padding: 20px;
    border-radius: 1em;
    border: none;
    box-shadow: $boxShadowPrimary2;
    opacity: 1;
    -webkit-text-fill-color: $neutralMedium;
}

.alignEnd {
    text-align: end;
}
