@import 'src/assets/styles/variables.module.scss';
@import 'src/assets/styles/fontMixins.scss';

.main {
    padding: 15px 0;
    text-decoration: none;
    width: 265px;
    display: flex;
    justify-content: center;

    span {
        @include text-17-medium;
        color: $textColor;
    }

    &:first-child {
        span {
            margin-left: -40px;
        }
    }
}

.active {
    background-image: url('./images/tab.svg');
    background-repeat: no-repeat;

    &:first-child {
        background-image: url('./images/firstTab.svg');
    }

    span {
        color: rebeccapurple;
    }
}
