@import 'src/assets/styles/variables.module.scss';

.main {
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: $transparentBlack;
    backdrop-filter: blur(2px);
    z-index: 100;
}
