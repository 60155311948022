.main {
    position: relative;
    height: 24px;
    width: 24px;

    &:hover {
        z-index: 20;

        .messageContainer {
            display: block;
        }
    }
}

.icon {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
        width: 20px;
    }
}

.messageContainer {
    width: 222px;
    position: absolute;
    /* top: -60px; */
    transform: translateX(-50%);
    left: 0.75em;
    bottom: 28px;
    display: none;
}

.rightMessageContainer {
    transform: translateX(-93%);
}

.bottomMessageContainer {
    top: 29px;
}

.bottomRightMessageContainer {
    top: 29px;
    transform: translateX(-93%);
}

.bottomLeftMessageContainer {
    top: 29px;
    transform: translateX(-13px);
}