@import 'src/assets/styles/variables.module.scss';
@import 'src/assets/styles/fontMixins.scss';

.button {
    border: none;
    background: #009F2D;
    border-radius: 1em;
    padding: 22px 0;
    color: $textColor2;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    @include text-20-regular;
    width: fit-content;
    // box-shadow: $boxShadowPrimary2;
    text-decoration: none;
}

.md {
    width: 224px;
}
