@import 'src/assets/styles/variables.module.scss';
@import 'src/assets/styles/fontMixins.scss';

@mixin title {
    color: $textColor;
    font-family: $font;
    font-weight: 700;
    font-style: normal;
    font-size: 28px;
    @media (max-width: 1024px) {
        font-size: 24px;
    }
}

@mixin text {
    color: $textColor;
    @include text-20-regular;
}

@mixin list {
    @include text-20-regular;
    padding: 0;
    list-style-position: inside;
    list-style: none;
}

@mixin list-item {
    padding: 6px 0;
    display: flex;
    align-items: center;
    @include text-20-regular;


    &:before {
        content: '';
        background-color: $secondary;
        display: inline-flex;
        width: 10px;
        min-width: 10px;
        border-radius: 16px;
        height: 10px;
        margin-right: 20px;
    }
}

@mixin purpleButton {
    background: $tertiaryGradient;
    border-radius: 16px;
    border: none;
    font-style: $font;
    color: $textColor2;
    font-size: 16px;
    padding: 15px;
    cursor: pointer;
    margin-left: 15px;
}

@mixin backButton {
    border: none;
    background: none;
    color: $textColor2;
    font-size: 18px;
    font-weight: 600;
    text-transform: uppercase;
    cursor: pointer;
    margin: 4rem 0;
    @media (max-width: 425px) {
        margin: 2rem 0;
    }
}

@mixin link {
    font-weight: 600;
    font-size: 18px;
    text-decoration: none;
    color: $greenColor;
}

@mixin scroll {
    /* width */
    &::-webkit-scrollbar {
        width: 6px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
        background: transparent;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
        background: rgba(196, 196, 196, 0.6);
        border-radius: 9px;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
        background: rgba(196, 196, 196, 0.9);
    }
}
