@import 'src/assets/styles/variables.module.scss';
@import 'src/assets/styles/fontMixins.scss';

.button {
    border: none;
    background: $defaultButtonGradient;
    border-radius: 1em;
    padding: 17px 65px;
    color: $textColor2;
    cursor: pointer;
    opacity: 1;
    display: block;
    visibility: initial;
    transition: visibility 0s, padding 0.3s, opacity 0.6s;
    @include text-17-regular;
    transition-delay: 0.1s;

    &:hover {
        box-shadow: $hoverShadow;
    }
}

.lock {
    visibility: hidden;
    opacity: 0;
    padding: 5px 10px;
}

button:disabled,
button[disabled]{
    background: $disabledButtonGradient;
}

@media only screen and (max-width: 770px) {
    .main {
        padding: 18px 48px;
    }
}
