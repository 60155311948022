@import 'src/assets/styles/variables.module.scss';

.main {
    position: relative;
    display: flex;
    padding: 0 5px;
    &:hover .content {
        display: block;
    }

    &:active .content {
        display: block;
    }
}

.content {
    position: absolute;
    right: 8px;
    display: none;
    padding-top: 30px;
}

.childrenContainer {
    background: $background;
    box-shadow: $boxShadowPrimary, -10px -10px 30px $neutralLight;
    border-radius: 5px;
}

.iconContainer {
    display: grid;
    grid-template-rows: 1fr 1fr 1fr;
    grid-gap: 2px;
    cursor: pointer;
}

.round {
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background: $primary3;
}
