@import 'src/assets/styles/variables.module.scss';
@import 'src/assets/styles/fontMixins.scss';

.firstContent {
    display: grid;
    grid-template-rows: 1fr 1fr;
    grid-gap: 1em;
    padding: 0 30px;

    @media (max-width: 800px) {
        grid-template-rows: 1fr calc(100vh - 88px);
        padding: 0 5px;
    }
}

.header {
    padding: 0 30px;
    display: grid;
    align-items: center;

    @media (max-width: 800px) {
        display: none;
    }

    h2 {
        margin: 0;
        @include h4-30-bold;
        color: $textColor;
    }
}

.mapContainer {
    overflow: hidden;
    border-radius: 1em;
}

.cardContainer {
    display: grid;
    grid-gap: 1em;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr;

    @media (max-width: 800px) {
        grid-template-columns: 1fr;
        grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr;
    }
}

.content {
    padding: 25px 0;
    height: 100%;
    display: grid;
    grid-template-rows: min-content minmax(200px, 100%);

    @media (max-width: 800px) {
        grid-template-rows: 100%;
        height: auto;
        padding: 0;
    }
}

.cardValue {
    flex: 1;
    display: flex;
    align-items: center;
    margin: 0;
    @include h4-30-bold;
    color: $textColor;
}

.valueContainer {
    display: flex;
    flex: 1;
    align-items: center;

    .value {
        margin: 0;
        @include h4-30-bold;
        color: $textColor;
    }
    .measure {
        @include text-13-medium;
        color: $textColor;
    }
}

.valueAndMeasure {
    display: flex;
    align-items: baseline;
}

.valueContainerWithFooter {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .valueAndMeasure {
        display: flex;
        align-items: baseline;

        .value {
            margin: 0;
            color: $textColor;
            @include h4-30-bold;
        }

        .measure {
            color: $textColor;
            @include text-13-medium;
        }
    }

    .increase {
        .arrow {
            transform: rotate(-90deg);
            color: $textColor3;
            display: inline-block;
        }

        .increaseValue {
            color: $textColor3;
        }

        @include text-15-regular;
    }

    .decrease {
        .arrow {
            transform: rotate(90deg);
            color: $errorColor;
            display: inline-block;
        }

        .increaseValue {
            color: $errorColor;
        }

        @media (max-width: 800px) {
            @include text-15-medium;
        }
    }

    .noData {
        .increaseValue {
            color: $grey;
        }
    }
}

.cardWithChart {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .valueRow {
        display: flex;
        align-items: center;
        padding: 3px 0;

        .valueColor {
            display: block;
            box-shadow: $boxShadow2;
            border-radius: 5px;
            height: 15px;
            width: 15px;
            margin-right: 11px;
        }

        .valueName {
            @include text-15-regular;
            white-space: pre-wrap;
            color: $textColor;
            margin-right: 11px;
        }

        .value {
            color: $grey;
            @include text-15-regular;
            @media (max-width: 800px) {
                font-size: 0.75rem;
            }
        }
    }
}

.titleValue {
    color: $grey;
    @include text-15-regular;
}

.selectedContainer {
    padding-right: 31px;
    text-transform: uppercase;
    color: $grey;
    @include text-13-medium;
    cursor: pointer;
}

.activeSelectedContainer {
    background: none;
    box-shadow: none;
}

.option {
    padding: 3px 0;
    color: $grey;
    text-transform: uppercase;
    @include text-13-medium;
    cursor: pointer;
    background: none;
}

.childrenContainer {
    border-radius: 0;
}

.circleWrapper {
    width: 100px;

    @media (max-width: 800px) {
        width: 80px;
    }
}
