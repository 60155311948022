@import 'src/assets/styles/variables.module.scss';

.switch {
    position: relative;
    display: inline-block;
    width: 64px;
    height: 32px;
}

.switch {
    .input {
        opacity: 0;
        width: 0;
        height: 0;
    }
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    transition: all 0.3s;

    border-radius: 1em;
    background: $neutralDark;
    box-shadow: $boxShadowPrimary2;
}

.input:checked + .slider {
    background: $secondary2;
}

.toggle {
    border-radius: 50%;
    position: absolute;
    height: 1em;
    width: 1em;
    top: 50%;
    left: 0;
    transform: translate(50%, -50%);

    transition: 0.3s;
    background: $neutralLight;
}

.input:checked + .slider {
    .toggle {
        left: 50%;
    }
}
