@import 'src/assets/styles/variables.module.scss';
@import 'src/assets/styles/mixins.scss';
@import 'src/assets/styles/fontMixins.scss';

.main {
    flex: 1 1;
    max-width: 100%;
    align-self: center;
    margin: 0 20px;
    width: 90%;

    @media (max-width: $mobile) {
        padding-top: 0px;
    }
}

.text {
    color: #ffffff;
    opacity: 0.7;
    @include text-13-regular;
}

.contentTitle {
    @include text-24-bold;
    text-align: left;
    color: $textColor2;
    margin-top: 0;
}

.contentSubTitle {
    @include text-13-regular;
    text-align: left;
    color: $textColor2;
    margin-top: 0;
}

.cookieError {
    color: $neutralLight;
    @include text-13-regular;

    .termsAndPolicyLink {
        color: $neutralLight;
        @include text-13-medium;
    }
}

.socialContainer {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    max-width: 490px;
    margin: 0 auto;
    padding: 0 10px;
}

.googleImg {
    margin-right: 13px;
}

.or span {
    overflow: hidden;
    text-align: center;
    @include text-13-regular;
    color: $textColor2;
    opacity: 0.5;
}

.or span:before,
.or span:after {
    background-color: $textColor2;
    content: '';
    display: inline-block;
    height: 1px;
    position: relative;
    vertical-align: middle;
    width: 50%;
    opacity: 0.5;
}

.or {
    text-align: center;
    padding: 0 20%;
}

.or span:before {
    right: 0.5em;
    margin-left: -50%;
}

.or span:after {
    left: 0.5em;
    margin-right: -50%;
}

.socialButton {
    background: rgba(255, 255, 255, 0.1);
}

.input {
    margin-bottom: 24px;
}
.submitButton {
    display: flex;
    justify-content: center;
    padding-top: 30%;
    margin-bottom: 24px;

    @media (max-width: $tablet) {
        padding-top: 25%;
    }
}

.warning {
    margin-bottom: 24px;
}

.apple {
    padding: 1em 1.125em;
}

.initialFormContainer {
    max-width: 405px;
    margin: 0 auto;
}

.termsAndPolicyContainer {
    display: flex;
    align-items: center;
}

.termsAndPolicyText {
    @include text-15-regular;
    color: $termsAndPolicyTextColor;
}

.termsAndPolicyLink {
    color: $textColor2;
    @include text-15-medium;
}
.environment {
    text-align: center;
    color: $white;
    text-transform: uppercase;
}
