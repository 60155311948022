/* Ionic Variables and Theming. For more info, please see:
http://ionicframework.com/docs/theming/ */

body {
    margin: 0;
    font-family: 'Montserrat', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
        'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 14px;
}

.highcharts-credits {
    display: none;
}

input,
select,
button {
    outline: none;
}

* {
    box-sizing: border-box;
    font-family: 'Montserrat', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
        'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}

/** Ionic CSS Variables **/
:root {
    /** primary **/
    --ion-color-primary: #3880ff;
    --ion-color-primary-rgb: 56, 128, 255;
    --ion-color-primary-contrast: #ffffff;
    --ion-color-primary-contrast-rgb: 255, 255, 255;
    --ion-color-primary-shade: #3171e0;
    --ion-color-primary-tint: #4c8dff;

    /** secondary **/
    --ion-color-secondary: #3dc2ff;
    --ion-color-secondary-rgb: 61, 194, 255;
    --ion-color-secondary-contrast: #ffffff;
    --ion-color-secondary-contrast-rgb: 255, 255, 255;
    --ion-color-secondary-shade: #36abe0;
    --ion-color-secondary-tint: #50c8ff;

    /** tertiary **/
    --ion-color-tertiary: #5260ff;
    --ion-color-tertiary-rgb: 82, 96, 255;
    --ion-color-tertiary-contrast: #ffffff;
    --ion-color-tertiary-contrast-rgb: 255, 255, 255;
    --ion-color-tertiary-shade: #4854e0;
    --ion-color-tertiary-tint: #6370ff;

    /** success **/
    --ion-color-success: #009F2D;
    --ion-color-success-rgb: 45, 211, 111;
    --ion-color-success-contrast: #ffffff;
    --ion-color-success-contrast-rgb: 255, 255, 255;
    --ion-color-success-shade: #28ba62;
    --ion-color-success-tint: #42d77d;

    /** warning **/
    --ion-color-warning: #ffc409;
    --ion-color-warning-rgb: 255, 196, 9;
    --ion-color-warning-contrast: #000000;
    --ion-color-warning-contrast-rgb: 0, 0, 0;
    --ion-color-warning-shade: #e0ac08;
    --ion-color-warning-tint: #ffca22;

    /** danger **/
    --ion-color-danger: #eb445a;
    --ion-color-danger-rgb: 235, 68, 90;
    --ion-color-danger-contrast: #ffffff;
    --ion-color-danger-contrast-rgb: 255, 255, 255;
    --ion-color-danger-shade: #cf3c4f;
    --ion-color-danger-tint: #ed576b;

    /** dark **/
    --ion-color-dark: #222428;
    --ion-color-dark-rgb: 34, 36, 40;
    --ion-color-dark-contrast: #ffffff;
    --ion-color-dark-contrast-rgb: 255, 255, 255;
    --ion-color-dark-shade: #1e2023;
    --ion-color-dark-tint: #383a3e;

    /** medium **/
    --ion-color-medium: #92949c;
    --ion-color-medium-rgb: 146, 148, 156;
    --ion-color-medium-contrast: #ffffff;
    --ion-color-medium-contrast-rgb: 255, 255, 255;
    --ion-color-medium-shade: #808289;
    --ion-color-medium-tint: #9d9fa6;

    /** light **/
    --ion-color-light: #f4f5f8;
    --ion-color-light-rgb: 244, 245, 248;
    --ion-color-light-contrast: #000000;
    --ion-color-light-contrast-rgb: 0, 0, 0;
    --ion-color-light-shade: #d7d8da;
    --ion-color-light-tint: #f5f6f9;
}

@media (prefers-color-scheme: dark) {
    /*
   * Dark Colors
   * -------------------------------------------
   */

    body {
        --ion-color-primary: #428cff;
        --ion-color-primary-rgb: 66, 140, 255;
        --ion-color-primary-contrast: #ffffff;
        --ion-color-primary-contrast-rgb: 255, 255, 255;
        --ion-color-primary-shade: #3a7be0;
        --ion-color-primary-tint: #5598ff;

        --ion-color-secondary: #50c8ff;
        --ion-color-secondary-rgb: 80, 200, 255;
        --ion-color-secondary-contrast: #ffffff;
        --ion-color-secondary-contrast-rgb: 255, 255, 255;
        --ion-color-secondary-shade: #46b0e0;
        --ion-color-secondary-tint: #62ceff;

        --ion-color-tertiary: #6a64ff;
        --ion-color-tertiary-rgb: 106, 100, 255;
        --ion-color-tertiary-contrast: #ffffff;
        --ion-color-tertiary-contrast-rgb: 255, 255, 255;
        --ion-color-tertiary-shade: #5d58e0;
        --ion-color-tertiary-tint: #7974ff;

        --ion-color-success: #009F2D;
        --ion-color-success-rgb: 47, 223, 117;
        --ion-color-success-contrast: #FFFFFF;
        --ion-color-success-contrast-rgb: 0, 0, 0;
        --ion-color-success-shade: #29c467;
        --ion-color-success-tint: #44e283;

        --ion-color-warning: #ffd534;
        --ion-color-warning-rgb: 255, 213, 52;
        --ion-color-warning-contrast: #000000;
        --ion-color-warning-contrast-rgb: 0, 0, 0;
        --ion-color-warning-shade: #e0bb2e;
        --ion-color-warning-tint: #ffd948;

        --ion-color-danger: #ff4961;
        --ion-color-danger-rgb: 255, 73, 97;
        --ion-color-danger-contrast: #ffffff;
        --ion-color-danger-contrast-rgb: 255, 255, 255;
        --ion-color-danger-shade: #e04055;
        --ion-color-danger-tint: #ff5b71;

        --ion-color-dark: #f4f5f8;
        --ion-color-dark-rgb: 244, 245, 248;
        --ion-color-dark-contrast: #000000;
        --ion-color-dark-contrast-rgb: 0, 0, 0;
        --ion-color-dark-shade: #d7d8da;
        --ion-color-dark-tint: #f5f6f9;

        --ion-color-medium: #989aa2;
        --ion-color-medium-rgb: 152, 154, 162;
        --ion-color-medium-contrast: #000000;
        --ion-color-medium-contrast-rgb: 0, 0, 0;
        --ion-color-medium-shade: #86888f;
        --ion-color-medium-tint: #a2a4ab;

        --ion-color-light: #222428;
        --ion-color-light-rgb: 34, 36, 40;
        --ion-color-light-contrast: #ffffff;
        --ion-color-light-contrast-rgb: 255, 255, 255;
        --ion-color-light-shade: #1e2023;
        --ion-color-light-tint: #383a3e;
    }

    /*
   * iOS Dark Theme
   * -------------------------------------------
   */

    .ios body {
        --ion-background-color: #ffffff;
        --ion-background-color-rgb: 0, 0, 0;

        --ion-text-color: #ffffff;
        --ion-text-color-rgb: 255, 255, 255;

        --ion-color-step-50: #0d0d0d;
        --ion-color-step-100: #1a1a1a;
        --ion-color-step-150: #262626;
        --ion-color-step-200: #333333;
        --ion-color-step-250: #404040;
        --ion-color-step-300: #4d4d4d;
        --ion-color-step-350: #595959;
        --ion-color-step-400: #666666;
        --ion-color-step-450: #737373;
        --ion-color-step-500: #808080;
        --ion-color-step-550: #8c8c8c;
        --ion-color-step-600: #999999;
        --ion-color-step-650: #a6a6a6;
        --ion-color-step-700: #b3b3b3;
        --ion-color-step-750: #bfbfbf;
        --ion-color-step-800: #cccccc;
        --ion-color-step-850: #d9d9d9;
        --ion-color-step-900: #e6e6e6;
        --ion-color-step-950: #f2f2f2;

        --ion-toolbar-background: #0d0d0d;

        --ion-item-background: #000000;
    }

    /*
   * Material Design Dark Theme
   * -------------------------------------------
   */

    .md body {
        --ion-background-color: #ffffff;
        --ion-background-color-rgb: 18, 18, 18;

        --ion-text-color: #ffffff;
        --ion-text-color-rgb: 255, 255, 255;

        --ion-border-color: #222222;

        --ion-color-step-50: #1e1e1e;
        --ion-color-step-100: #2a2a2a;
        --ion-color-step-150: #363636;
        --ion-color-step-200: #414141;
        --ion-color-step-250: #4d4d4d;
        --ion-color-step-300: #595959;
        --ion-color-step-350: #656565;
        --ion-color-step-400: #717171;
        --ion-color-step-450: #7d7d7d;
        --ion-color-step-500: #898989;
        --ion-color-step-550: #949494;
        --ion-color-step-600: #a0a0a0;
        --ion-color-step-650: #acacac;
        --ion-color-step-700: #b8b8b8;
        --ion-color-step-750: #c4c4c4;
        --ion-color-step-800: #d0d0d0;
        --ion-color-step-850: #dbdbdb;
        --ion-color-step-900: #e7e7e7;
        --ion-color-step-950: #f3f3f3;

        --ion-item-background: #1e1e1e;

        --ion-toolbar-background: #1f1f1f;

        --ion-tab-bar-background: #1f1f1f;
    }
}

@font-face {
    font-family: 'Montserrat';
    src: local('Montserrat Black'), local('Montserrat-Black'),
        url('../assets/fonts/Montserrat-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Montserrat';
    src: local('Montserrat Extrabold'), local('Montserrat-ExtraBold'),
        url('../assets/fonts/Montserrat-ExtraBold.ttf') format('truetype');
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: 'Montserrat';
    src: local('Montserrat Semibold Italic'), local('Montserrat-SemiBoldItalic'),
        url('../assets/fonts/Montserrat-SemiBoldItalic.ttf') format('truetype');
    font-weight: 600;
    font-style: italic;
}

@font-face {
    font-family: 'Montserrat';
    src: local('Montserrat Thin'), local('Montserrat-Thin'),
        url('../assets/fonts/Montserrat-Thin.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'Montserrat';
    src: local('Montserrat Light'), local('Montserrat-Light'),
        url('../assets/fonts/Montserrat-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Montserrat';
    src: local('Montserrat Black Italic'), local('Montserrat-BlackItalic'),
        url('../assets/fonts/Montserrat-BlackItalic.ttf') format('truetype');
    font-weight: 900;
    font-style: italic;
}

@font-face {
    font-family: 'Montserrat';
    src: local('Montserrat Bold Italic'), local('Montserrat-BoldItalic'),
        url('../assets/fonts/Montserrat-BoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'Montserrat';
    src: local('Montserrat Thin Italic'), local('Montserrat-ThinItalic'),
        url('../assets/fonts/Montserrat-ThinItalic.ttf') format('truetype');
    font-weight: 100;
    font-style: italic;
}

@font-face {
    font-family: 'Montserrat';
    src: local('Montserrat Bold'), local('Montserrat-Bold'),
        url('../assets/fonts/Montserrat-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
   font-family: 'Montserrat';
   src: local('Montserrat Regular Italic'), local('Montserrat-RegularIt'),
       url('../assets/fonts/Montserrat-Italic.ttf') format('truetype');
   font-weight: normal;
   font-style: italic;
}

@font-face {
    font-family: 'Montserrat';
    src: local('Montserrat Extrabold Italic'), local('Montserrat-ExtraBoldItalic'),
        url('../assets/fonts/Montserrat-ExtraBoldItalic.ttf') format('truetype');
    font-weight: 800;
    font-style: italic;
}

@font-face {
    font-family: 'Montserrat';
    src: local('Montserrat Regular'), local('Montserrat-Regular'),
        url('../assets/fonts/Montserrat-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Montserrat';
    src: local('Montserrat Light Italic'), local('Montserrat-LightItalic'),
        url('../assets/fonts/Montserrat-LightItalic.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'Montserrat';
    src: local('Montserrat Semibold'), local('Montserrat-SemiBold'),
        url('../assets/fonts/Montserrat-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}

/* html:not(.hydrated) body {
  display: none;
} */
html:not(.hydrated) body {
    display: inherit;
}

.page {
    height: 100%;
    display: flex;
    flex-direction: column;
    width: 100%;
}

@media (max-width: 425px) {
    .page {
        height: 100%;
        display: block;
        width: 100%;
    }
}

img {
    max-width: inherit;
}

#ionic-page {
    padding-top: var(--ion-safe-area-top);
}

.picker-below-highlight.sc-ion-picker-ios {
    background: linear-gradient(
        to top,
        #fff 30%,
        rgba(255, 255, 255, 0.7) 100%
    );
    border-top: 1px solid #c8c7cc;
}

.picker-above-highlight.sc-ion-picker-ios {
    background: linear-gradient(
        to bottom,
        #fff 20%,
        rgba(255, 255, 255, 0.7) 100%
    );
    border-bottom: 1px solid #c8c7cc;
}

.picker-wrapper.sc-ion-picker-ios {
    color: #000000;
}

.picker-ios .picker-toolbar {
    border-bottom: 0.55px solid #c8c7cc;
}

.swiper-container{
    width: 100%;
}
.swiper-container-horizontal
    > .swiper-pagination-bullets
    .swiper-pagination-bullet {
    margin: 0 4px;
    transition: width 300ms ease-in-out;
}
.swiper-pagination-bullet-active {
    width: 2rem;
    border-radius: 4px;
    transition: width 300ms ease-in-out;
    background-color: #1E3466;
}
.make-disable{
    filter: grayscale(1);
    user-select: none;
    pointer-events: none;
}
.make-disable input{
    filter: grayscale(1);
    user-select: none;
    pointer-events: none;
    opacity: 0.5;
    border: none;
}
.user-select-none{
    user-select: none;
    pointer-events: none;
}
.ChargerCard_ionAlert__1-IvB .alert-wrapper {
    background-color: #FFFFFF !important;
    border-radius: 14px;
}
.restrictedHoursPrompt .alert-wrapper {
    background-color: #FFFFFF !important;
}
.restrictedHoursPrompt .alert-message {
    color: #000000 !important;
}
.restrictedHoursPrompt .alert-button-group {
   border-top: 0.55px solid rgba(0, 0, 0, 0.24);
}
.ChargerCard_ionAlert__1-IvB .alert-wrapper .alert-head{
    display: none;
}

.ChargerCard_ionAlert__1-IvB .alert-wrapper .alert-message{
    padding-top: 20px !important;
}

.ChargerCard_ionAlert__1-IvB .alert-wrapper .alert-button-group{
    display: flex !important;
    flex-direction: column !important;
    flex-wrap: nowrap !important;
    padding: 0px;
}

.Info_ionAlert__1CbLE .alert-wrapper {
    border-radius: 14px;
    background-color: #FFFFFF !important;
}
.Info_ionAlert__1CbLE .alert-wrapper .alert-head{
    display: none;
}

.Info_ionAlert__1CbLE .alert-wrapper .alert-message{
    padding-top: 20px !important;
}

.Info_ionAlert__1CbLE .alert-wrapper .alert-button-group{
    display: flex !important;
    flex-direction: column !important;
    flex-wrap: nowrap !important;
    padding: 0px;
}

.OptimiseYourCharger_ionAlert__1nBq0 .alert-wrapper {
    border-radius: 14px;
    background-color: #FFFFFF !important;
}
.alert-wrapper {
    border-radius: 14px;
    background-color: #FFFFFF !important;
}
.alert-message{
    color: #000000 !important;
}
.alert-title.sc-ion-alert-ios{
    color: #000000;
}
.OptimiseYourCharger_ionAlert__1nBq0 .alert-wrapper .alert-head{
    display: none;
}

.OptimiseYourCharger_ionAlert__1nBq0 .alert-wrapper .alert-message{
    padding-top: 20px !important;
}

.OptimiseYourCharger_ionAlert__1nBq0 .alert-wrapper .alert-button-group{
    display: flex !important;
    flex-direction: column !important;
    flex-wrap: nowrap !important;
    padding: 0px;
}
.alert-button.sc-ion-alert-ios{
    border-right: 0.55px solid rgba(0,0,0, 0.2);
}
.d-none{
    display: none !important;
}
.DateRangePicker__Date {
    border: 0 !important;
}
.DateRangePicker__WeekdayHeading{
    border: 0 !important;
}
.DateRangePicker__Date--weekend{
    background-color: #fbfbfb !important;
}
.DateRangePicker__MonthDates{
    border: 0 !important;
    border-spacing: 0 10px !important;
}
.DateRangePicker__CalendarHighlight{
    margin: 0 auto !important;
    width: 30px !important;
    height: 30px !important;
}
body.scanner-active {
    --background: transparent;
    --ion-background-color: transparent;
  }
body.scanner-active .AddCharger_ionicContent__2BXQ3 {
    --background: transparent;
    --ion-background-color: transparent;
  }
body.scanner-active .ChargerRegistration_closeAndSkipbutton__TXymQ {
    display: none;
  }
