@import 'src/assets/styles/variables.module.scss';
@import 'src/assets/styles/fontMixins.scss';

.main {
    position: relative;
    width: 100%;
}

.datePiker {
    display: none;
    z-index: 3;
    position: absolute;
    background: $background;
    box-shadow: $boxShadowPrimary, -10px -10px 30px $neutralLight;
    border-radius: 1em;
    grid-template-columns: max-content;
    padding: 30px;
    top: 75px;
    left: 50%;
    transform: translateX(-50%);
    @media (max-width: 800px) {
        padding: 10px 0;
    }
}
.topPosition {
    bottom: 75px;
    top: auto;
}

.activePiker {
    display: grid;
}

.input {
    background: $neutralDark;
    box-shadow: $boxShadowPrimary2;
    border-radius: 1em;
    border: none;
    display: grid;
    grid-template-columns: 1fr 24px;
    grid-template-rows: 1fr;
    padding: 13px 15px;
    align-items: center;
    cursor: pointer;
    max-width: 450px;
}

.value {
    @include text-17-regular;
    color: $textColor;
}

.placeholder {
    @include text-17-regular;
    color: $grey;
}

.buttonContainer {
    padding-top: 30px;
    display: flex;
    justify-content: flex-end;
    @media (max-width: 800px) {
        justify-content: center;
    }
}

.corner {
    position: absolute;
    display: flex;
    top: 0;
    right: 50%;
    transform: translate(50%, -100%) rotate(180deg);
    g {
        fill: $background;
    }
}

:global {
    .customPikerStyles {
        @media (max-width: 800px) {
            display: flex;
            flex-direction: column;
        }
        .DateRangePicker__MonthHeader {
            color: $textColor;
            @include text-20-regular;
        }

        .DateRangePicker__WeekdayHeading abbr[title] {
            color: $textColor;
            @include text-13-regular;
            text-transform: uppercase;
            opacity: 0.7;
        }

        .DateRangePicker__DateLabel {
            @include text-13-regular;
            color: $textColor;
        }
        .DateRangePicker__CalendarHighlight {
            background: transparent;
            border: 2px solid $primaryGray;
            border-radius: 8px;
            padding-bottom: 50%;
            height: 32px;
            width: 37px;
            left: 0;
            right: 0;
            bottom: auto;
            top: 0;
        }

        .DateRangePicker__CalendarSelection {
            background-color: $textColor3;
            border: 1px solid $greenColor;
            height: 32px;
            width: 37px;
            left: 0;
            right: 0;
            border-radius: 4px;
            bottom: auto;
            top: 0;
        }

        .DateRangePicker__CalendarSelection--start {
            border-radius: 8px 4px 4px 8px;
            left: 0;
        }

        .DateRangePicker__CalendarSelection--end {
            border-radius: 4px 8px 8px 4px;

            right: 0;
        }

        .DateRangePicker__Date--is-selected {
            .DateRangePicker__DateLabel {
                color: $textColor2;
            }
        }

        .DateRangePicker__Date--today {
            .DateRangePicker__FullDateStates {
                background: transparent;
                border: 2px solid $primaryGray;
                border-radius: 8px;
                padding-bottom: 50%;
                height: 32px;
                width: 37px;
                left: 0;
                right: 0;
                bottom: auto;
                top: 0;
            }
        }
    }
}

.timeContainer {
    display: flex;
    flex-direction: column;
}

.timeTitle {
    display: flex;
    align-items: center;
    justify-content: center;
    span {
        color: $textColor;
        @include text-17-medium;
    }
}

.timersContainer {
    display: flex;
    justify-content: center;
}

.pickerContainer {
    display: flex;
    flex-direction: column;
    span {
        color: $textColor;
        @include text-13-medium;
    }
}

:global {
    .MuiInputBase-input {
        color: $textColor;
    }
}
