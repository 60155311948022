@import 'src/assets/styles/variables.module.scss';

.main {
    position: absolute;
    top: 0;
    width: 100%;
    left: 0;
    padding: 1em;
    display: none;
    transform: translate3d(0, 0, 0);
    background: $neutralLight;
    -webkit-transform: translate3d(0, 0, 0);
    z-index: 9999;
    border-bottom: 4px solid $background;
    @media (max-width: 800px) {
        display: block;
    }
}

.content {
    display: grid;
    grid-template-columns: 48px 1fr minmax(48px, min-content);
}

.buttonContainer {
    background: none;
    border: none;
    padding: 0;
}

.middle {
    display: flex;
    justify-content: center;
    align-items: center;
}

.right {
    display: flex;
    justify-content: center;
    align-items: center;
}

.logoContainer {
    width: 20%;
    max-width: 57px;
    img {
        width: 100%;
    }
}
