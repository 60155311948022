.advantages {
    width: 100%;
    position: relative;
}

.backGround {
    position: absolute;
    width: 103%;
    bottom: 0;
    display: flex;
    height: 100%;
    background-repeat: no-repeat;
    background-position: bottom;
}

.content {
    position: relative;
    padding: 55px 75px;
}

.logo {
    display: block;
}

.logoLike {
    width: 25%;
    display: block;
}

.logoLike img {
    height: auto;
    width: 100%;
    display: block;
    max-width: 170px;
}

// responsive

@media only screen and (max-width: 770px) {
    .SignIn {
        flex-direction: column;
    }

    .advantages {
        position: absolute;
        z-index: 2;
        width: 50%;
        max-width: 240px;
        top: 2.5%;
        left: 50%;
        transform: translateX(-50%);
        border-radius: 0px 0px 24px 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 20px 0;
        max-height: 90px;
    }

    .backGround {
        display: none;
    }

    .logo {
        display: block;
        width: 100%;
    }

    .content {
        padding: 0;
        width: 100%;
    }

    .logoLike {
        width: 40%;
        margin: 0 auto;
    }
}
