@import 'src/assets/styles/variables.module.scss';
@import 'src/assets/styles/fontMixins.scss';

.main {
    display: flex;
    max-height: 56px;
    position: relative;
    label {
        color: $textColor;
        position: absolute;
        z-index: 99;
        margin: 1rem 1.2rem;
        font-size: 12px;
        font-weight: 400;
        line-height: 15px;
        letter-spacing: 0em;
        text-align: left;
    }
}

.input {
    background: $neutralLight;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
    border-radius: 1em;
    padding: 20px;
    border: none;
    outline: none;
    @include text-17-regular;
    color: $textColor;
    width: 100%;
    height: 68px;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;

    --padding-top: 38px;
    --padding-end: 20px;
    --padding-bottom: 20px;
    --padding-start: 20px;
    --border-radius: 10px;
}

.password {
    padding-right: 50px;
}

.error {
    border: 2px solid;
    border-color: $errorColor;
}

.eye {
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translate(0, -50%);
    margin: 0;
    padding: 0;
    background: none;
}

@media only screen and (max-width: 770px) {
    .main {
        max-height: 52px;
    }
}
