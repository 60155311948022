@import 'src/assets/styles/variables.module.scss';

.switch {
    position: relative;
    display: inline-block;
    width: 105px;
    height: 53px;

    @media (max-width: 800px) {
        width: 85px;
        height: 45px;
    }
}

.switch {
    .input {
        opacity: 0;
        width: 0;
        height: 0;
    }
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 0.75em;
    background: $primaryGray;
    box-shadow: inset 2px 2px 8px rgba(94, 133, 170, 0.8);
    -webkit-transition: 0.3s;
    transition: 0.3s;
}

.input:checked + .slider {
    background: $secondary;
    box-shadow: inset 2px 2px 8px rgba(94, 133, 170, 0.8);
    border-radius: 0.75em;
}

.toggle {
    background: $background;
    box-shadow: 5px 2px 1em #88a5bf, inset 0px -1px 1px rgba(85, 102, 123, 0.6);
    border-radius: 11px;
    position: absolute;
    height: calc(100% - 2px);
    width: calc(50% - 2px);
    top: calc(50% + 0.5px);
    left: 0;
    transform: translate(1px, -50%);
    -webkit-transition: 0.3s;
    transition: 0.3s;

    img {
        width: 70%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-58%, -50%);
    }

    @media (max-width: 800px) {
        top: 50%;
    }
}

.circle {
    background: $toggleGradient;
    box-shadow: -2px -1px 4px rgba(255, 255, 255, 0.4),
        inset -4px -3px 4px rgba(79, 98, 120, 0.2),
        inset 3px 3px 5px rgba(213, 230, 245, 0.4);
    backdrop-filter: blur(28px);
    /* Note: backdrop-filter has minimal browser support */

    border-radius: 26px;

    height: 30%;
    width: 30%;
}

.input:checked + .slider {
    .toggle {
        left: 50%;

        transform: translate(1px, -50%);
    }
}
