@import 'src/assets/styles/variables.module.scss';
@import 'src/assets/styles/fontMixins.scss';

.autocomplete {
    box-sizing: border-box;
    display: flex;
}

.inputContainer {
    display: grid;
    grid-template-columns: minmax(50px, 420px) 54px;
    background: $neutralDark;
    border-radius: 1em;
    padding: 14px;
    box-shadow: $boxShadowPrimary2;
    .autocompleteInput {
        background: none;
        border: none;
        outline: none;
        color: $textColor;
        @include text-17-regular;
        width: 100%;
    }

    img {
        margin: auto;
    }
}
