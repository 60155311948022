@import 'src/assets/styles/variables.module.scss';
@import 'src/assets/styles/fontMixins.scss';
@import 'src/assets/styles/signTemplate.scss';

.ionicContent {
    @include ionic-content;
}

.page {
    @include page;
}

.container {
    @include container;
}

.section {
    @include section;
}

.footer {
    @include footer;
    .text {
        padding: 0;
        @include text-15-regular;
        color: $neutralLight;
        margin: 10px 0;
    }
}
.header {
    margin-bottom: 100px;
}

.text {
    @include text;
    color: $neutralLight;
    text-align: center;
}

.link {
    @include link;
    background: none;
}

.title {
    @include text-24-bold;
    color: $neutralLight;
    padding: 0;
    margin: 0;
    text-align: center;
}

.number {
    @include text-24-bold;
    padding: 0;
    margin: 5px 0;
    color: $tertiary2;
    text-align: center;
}

.shareContainer {
    margin-top: 73px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.shareText {
    @include text;
}

.shareButton {
    display: flex;
    background: none;
    border: none;
    align-items: center;
    justify-content: center;
}
