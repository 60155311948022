@import 'src/assets/styles/variables.module.scss';
@import 'src/assets/styles/fontMixins.scss';

.button {
    background: $transparentBlack;
    background-blend-mode: overlay;
    backdrop-filter: blur(43.444px);
    border: none;
    padding: 1em 23px;
    border-radius: 1em;
    color: rgba(255, 255, 255, 0.8);
    @include text-13-regular;
    text-transform: uppercase;
    margin: 5px 0;
    cursor: pointer;
}

.content {
    display: flex;
    align-items: center;
    white-space: nowrap;
}

@media only screen and (max-width: 870px) {
    .content {
        @include text-13-regular;
        @media (max-width: 350px) {
            font-size: 0.5rem;
        }
    }

    .content img {
        height: 1rem;
    }

    .button {
        padding: 14px 18px;
    }
}
