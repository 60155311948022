@import 'src/assets/styles/variables.module.scss';

@mixin h1-60-bold {
    font-family: $font;
    font-weight: bold;
    font-size: 60px;

    @media (max-width: $tablet) {
        font-size: 34px;
    }
}

@mixin h2-50-bold {
    font-family: $font;
    font-weight: bold;
    font-size: 50px;

    @media (max-width: $tablet) {
        font-size: 32px;
    }
}

@mixin h3-40-bold {
    font-family: $font;
    font-weight: bold;
    font-size: 40px;

    @media (max-width: $tablet) {
        font-size: 28px;
    }
}

@mixin h4-30-bold {
    // page title
    font-family: $font;
    font-weight: bold;
    font-size: 30px;

    @media (max-width: $tablet) {
        font-size: 24px;
    }
}

@mixin text-24-bold {
    font-family: $font;
    font-weight: bold;
    font-size: 24px;

    @media (max-width: $tablet) {
        font-size: 20px;
    }
}

@mixin text-24-medium {
    font-family: $font;
    font-weight: 500;
    font-size: 24px;

    @media (max-width: $tablet) {
        font-size: 20px;
    }
}

@mixin text-20-regular {
    font-family: $font;
    font-weight: normal;
    font-size: 1.33rem;

    @media (max-width: $tablet) {
        font-size: 1rem;
    }
}

@mixin text-20-bold {
    font-family: $font;
    font-weight: bold;
    font-size: 1.33rem;

    @media (max-width: $tablet) {
        font-size: 1rem;
    }
}
@mixin text-17-bold {
    // medium title
    font-family: $font;
    font-weight: bold;
    font-size: 1rem;
}

@mixin text-17-medium {
    // medium title
    font-family: $font;
    font-weight: 600;
    font-size: 1rem
}

@mixin text-17-regular {
    //button
    //input
    font-family: $font;
    font-weight: normal;
    font-size: 1rem;
}

@mixin text-15-regular {
    font-family: $font;
    font-weight: normal;
    font-size: 1rem;
}

@mixin text-15-medium {
    font-family: $font;
    font-weight: 600;
    font-size: 1rem;
}

@mixin text-13-medium {
    font-family: $font;
    font-weight: 600;
    font-size: 0.75rem;
}

@mixin text-13-regular {
    font-family: $font;
    font-weight: normal;
    font-size:  0.75rem;
}
