@import 'src/assets/styles/variables.module.scss';
@import 'src/assets/styles/fontMixins.scss';

.current {
    height: 88%;
    width: 88%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
    color: $textColor2;
    @include text-13-regular;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--bgColor);
    z-index: 5;
    border-radius: 50%;
}

.main {
    overflow: hidden;
    position: relative;
    pointer-events: none;
}
