$circle-bg: red;
$circle-size: 70px;
$circle-animation-time: 1s;

.container {
    display: grid;
    width: 100px;
}

.circlebeat {
    width: 100%;
    height: auto;
    height: auto;
    -webkit-animation: circlebeat $circle-animation-time ease-in-out 0s infinite;
    -moz-animation: circlebeat $circle-animation-time ease-in-out 0s infinite;
    animation: circlebeat $circle-animation-time ease-in-out 0s infinite;
}

@keyframes circlebeat {
    0% {
        transform: scale(1);
    }
    20% {
        transform: scale(1.5);
    }
    40% {
        transform: scale(1.2);
    }
    60% {
        transform: scale(1.3);
    }
    100% {
        transform: scale(1);
    }
}
