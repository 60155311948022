@import 'src/assets/styles/variables.module.scss';

.container {
    padding: 2em 1em;
}
.headingText {
    font-size: 1.1em;
    font-weight: 600;
    line-height: 1.5em;
    margin: 1em 0;
    color: $textColor;
    text-align: center;
}
.description {
    font-weight: 500;
    line-height: 1.65em;
    color: $textColor;
    i {
        font-style: italic;
    }
    margin: 1.5rem 0;
}
.href {
    color: $textColor3;
    font-weight: 700;
    font-size: 0.875em;
    text-decoration: none;
    display: flex;
    align-items: center;
    img {
        margin: 0 0.5em;
    }
}
.toggleHeading {
    font-weight: 700;
    font-size: 1.1em;
    line-height: 1.5em;
    color: $textColor;
    margin: 1.5em 0;
}
.footer {
    position: fixed;
    width: 100%;
    bottom: 0;
    padding: 1.5em;
    background: #fbfbfb7d;
    display: flex;
    justify-content: space-around;
    border-top: 1px solid #e5e6e6;
    backdrop-filter: blur(20px);
    z-index: 99;

    button {
        padding: 0.75em 3.5em;
        font-size: 1em;
        line-height: 1.65em;
        border-radius: 2em;
        font-weight: 700;
        &.cancel {
            border: 1px solid $textColor;
            color: $textColor;
        }
        &.save {
            background-color: $textColor3;
            border: 1px solid $textColor3;
            color: $textColor2;
            &.disabled {
                border: 1px solid #f0f0f1;
                background: #f0f0f1;
                color: #a5a6a7;
                cursor: default;
            }
        }
    }
}
:global {
    .prompt {
        .alert-wrapper {
            .alert-head {
                .alert-title {
                    color: $textColor;
                }
            }
            .alert-message {
                color: $textColor;
            }
        }
    }
}
.toggleCard {
    background: #fbfbfb;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    border-radius: 1rem;
    padding: 0.5rem;
    padding-left: 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .title {
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        color: #0f1052;
    }
}
.toggleSelect{
    margin-top: 1rem;
    margin-bottom: 1rem;
}
 .title {
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        color: #0f1052;
    }
    .formContianer{
        padding-top: 0rem;
        padding-bottom: 20rem;
    }
    .rowClass {
        display: flex;
        align-items: flex-start;
        margin: 1em 0;
        p {
            text-align: justify;
            font-size: 0.75rem;
            color: #4a5bf0;
            margin: 0;
        }
        img {
            margin: 0.25em 0;
            margin-right: 0.5em;
        }
    }