@import 'src/assets/styles/variables.module.scss';
@import 'src/assets/styles/mixins.scss';

.background {
    height: 100%;

    @media (max-width: 800px) {
        background: $background;
    }
}

.main {
    height: 100%;
    background: $background;
    overflow: auto;
    @include scroll;

    @media (max-width: 800px) {
        padding: 10px;
        padding-top: 100px;
        border-radius: 0px;
    }
}

.content {
    padding: 25px 0;
    height: 100%;
    display: grid;
    grid-template-rows: min-content minmax(200px, 100%);
    @media (max-width: 800px) {
        grid-template-rows: 100%;
    }
}

.header {
    padding: 0 30px;
    display: grid;
    grid-template-columns: 2fr 3fr;
    align-items: center;

    @media (max-width: 800px) {
        display: none;
    }

    h2 {
        margin: 0;
        @include h4-30-bold;
        color: $textColor;
    }

    form {
        display: flex;
        justify-content: flex-end;
    }
}

.exportButton {
    text-transform: uppercase;
    @include text-17-regular;
    color: $textColor2;
    background: $exportButtonGradient;
    border-radius: 1em;
    text-decoration: none;
    display: flex;
    padding: 17px 49px;
    align-items: center;
    border: none;
    white-space: nowrap;
    margin-left: 1em;
}

.tableContainer {
    display: grid;
    grid-template-rows: 100%;
    max-width: 932px;
}
