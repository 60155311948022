@import 'src/assets/styles/variables.module.scss';
@import 'src/assets/styles/fontMixins.scss';

.header {
    display: flex;
}

.index {
    height: 43px;
    width: 43px;
    display: flex;
    justify-content: center;
}

.title {
    flex: 1;
}

.indexNumber {
    display: flex;
    height: 100%;
    width: 100%;
    border: 3px solid $neutralDark;
    background: $neutralDark;
    align-items: center;
    justify-content: center;
    border-radius: 1em;
    color: $greenColor;
    @include text-20-bold;
    box-sizing: border-box;
}

.titleText {
    @include text-24-bold;
    color: $textColor;
    margin: 0;
    opacity: 0.5;

}

.title {
    display: flex;
    align-items: center;
    padding-left: 25px;
}

.line {
    height: 100%;
    width: 7px;
    background: $primaryGray;
    opacity: 0.1;
}

.step {
    &:last-child .line {
        display: none;
    }
}

.activeIndex {
    background: $neutralLight;
    color: $textColor;
}

.titleTextActive {
    opacity: 1;
}

.finishedText {
    opacity: 1;
    color: $greenColor;
}

.finishedIndex {
    background: $greenColor;
    border: 3px solid $greenColor;
}
