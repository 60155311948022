@import 'src/assets/styles/variables.module.scss';
@import 'src/assets/styles/fontMixins.scss';

.main {
    max-width: 540px;
    padding: 30px 16px;
    background: $neutralLight;
    // box-shadow: -10px -10px 30px $neutralLight, $boxShadowPrimary;
    border-radius: 1em;
    position: relative;
    margin: 16px auto;
}

.text {
    color: $textColor;
    @include text-17-regular;
    text-align: center;
}
.errorTitle {
    text-align: center;
    color: $errorColor;
    font-size: 1.5em;
    font-weight: 700;
    margin-top: 0;
}

.errorText {
    text-align: center;
    color: $errorColor;
    line-height: 1.5em;
    font-size: 1em;
    max-width: 90%;
    margin: 1rem auto;
    }

    .downloadConnectContainer {
        display: flex;
        justify-content: center;
        margin-top: 2em;
        margin-bottom: 0.75em;
    
        .downloadConnect {
            background-color: $greenColor;
            color: $textColor2;
            font-size: 1em;
            line-height: 1.5em;
            padding: 1em 3em;
            border-radius: 1em;
        }
}

.current {
    text-align: center;
    width: 100%;

    .value {
        color: $textColor;
        padding: 5px 0;
        @include text-24-bold;
    }

    .percent {
        color: $textColor;
    }
}

.footer {
    position: absolute;
    display: flex;
    padding-bottom: 10px;
    padding-right: 20px;
    justify-content: flex-end;
    width: 100%;
    bottom: 0;
}

.contactUs {
    @include text-17-medium;
    color: $secondary;
    text-decoration: underline;
}

.circle {
    max-width: 150px;
    max-height: 150px;
    min-height: 150px;
    position: relative;
    margin: 0 auto;
}

.loader {
    border: 8px solid $background2;
    border-top: 8px solid $secondary;
    border-radius: 50%;
    width: 107px;
    height: 106px;
    position: absolute;
    top: 20px;
    left: 21px;
    animation: spin 2s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.successText {
    color: $secondary;
    @include text-17-regular;
    text-align: center;
}
