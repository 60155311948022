@import 'src/assets/styles/variables.module.scss';
@import 'src/assets/styles/fontMixins.scss';

.main {
    background: $background;
    box-shadow: $boxShadowInvert;
    border-radius: 1em;
    padding: 14px;
    display: grid;
    grid-template-rows: min-content 1fr;

    header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        h6 {
            margin: 0;
            @include text-13-medium;
            color: $textColor;
            text-transform: uppercase;
        }
    }
}

.content {
    display: flex;
}
