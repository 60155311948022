@import 'src/assets/styles/variables.module.scss';
@import 'src/assets/styles/fontMixins.scss';

.SignInFormContainer {
    width: 100%;
    border-radius: 1em 0px 0px 1em;
    position: relative;
    display: flex;
    flex-direction: column;
    flex: 1;
}


.formHeader {
    display: flex;
    padding: 25px;

    justify-content: flex-end;

}

.signIn{
    position: relative;
    justify-content: center;
    margin-top: 4rem;
}

.headerText {
    @include text-17-regular;
    color: $textColor2;
    left: 80%;
    top: 4%;
}

.headerLink {
    @include text-15-medium;
    color: $textColor2;
    text-decoration-line: none;
    cursor: pointer;
}

// responsive

@media only screen and (max-width: 770px) {
    .SignInFormContainer {
        width: 100%;
        border-radius: 0;
        min-height: 100%;
    }

    .formHeader {
        margin-top: 0.5rem;
        // justify-content: right;
    }
    .signIn{
        position: relative;
        margin-top: 4rem;
        justify-content: center;
    }

}
