@import 'src/assets/styles/variables.module.scss';

.main {
    position: absolute;
    background-color: $neutralLight;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;
    border-radius: 2px;
    width: 40px;
    height: 40px;
    touch-action: none;
    display: flex;
    border: none;
    justify-content: center;
    margin: 10px;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    img {
        width: 30px;
        height: 30px;
        margin: auto;
    }
}
