@import 'src/assets/styles/variables.module.scss';
@import 'src/assets/styles/fontMixins.scss';

.autocomplete {
    box-sizing: border-box;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    background: $neutralLight;
    box-shadow: $boxShadowPrimary;
    border-radius: 0 0 1em 1em;
    padding: 24px;
    display: flex;
    @media (max-width: 800px) {
        display: none;
    }
}

.inputContainer {
    display: grid;
    grid-template-columns: 420px 54px;
    background: $neutralDark;
    border-radius: 1em;
    padding: 14px;
    box-shadow: $boxShadowPrimary2;
    .autocompleteInput {
        max-width: 450px;
        background: none;
        border: none;
        outline: none;
        color: $textColor;
        width: 100%;
        @include text-17-regular;
    }

    img {
        margin: auto;
    }
}
