@import 'src/assets/styles/variables.module.scss';
@import 'src/assets/styles/mixins.scss';
@import 'src/assets/styles/fontMixins.scss';

.backButtonContainer {
    display: flex;
    justify-content: center;
}

.backButton {
    border: none;
    background: none;
    @include text-17-regular;
    color: $textColor2;
    text-transform: uppercase;
    cursor: pointer;
    margin: 4rem 0;
    margin-top: 0.5rem;
    justify-content: right;
    position: absolute;
    top: 5%;
    left: 2%;
}
.main {
    flex: 1 1;
    max-width: 405px;
    align-self: center;
    margin: 0 20px;
    width: 90%;
    padding-top: 5px;
}

.contentTitle {
    @include text-24-bold;
    text-align: left;
    color: $textColor2;
    margin-top: 2rem;
}

.submitButton {
    display: flex;
    justify-content: center;
    padding-top: 40px;
    margin-top: 80%;

    @media (max-width: $tablet) {
        padding-top: 20px;
        margin-top: 75%;
    }

    @media (max-width: 375px) {
        padding-top: 10px;
        margin-top: 55%;
    }
}

.input {
    margin-bottom: 24px;
}

.text {
    @include text-13-regular;
    color: $textColor2;
    opacity: 0.7;
    text-align: left;
}

.warning {
    margin-bottom: 24px;
}
