@import "src/assets/styles/variables.module.scss";
@import 'src/assets/styles/fontMixins.scss';
@import 'src/assets/styles/variables.module.scss';

.navLink {
    display: flex;
    text-decoration: none;
    position: relative;
    padding: 7px 0;
    margin-bottom: 15px;

    @media (max-width: 1024px) {
        padding: 5px 0;
        margin-bottom: 15px;
    }
}
.linkIcon {
    margin-bottom: 10px;
    @media (max-width: 800px) {
        margin: 0 auto;
    }
}

.indicator {
    border-radius: 1em;
    height: 100%;
    width: 7px;
    background: $secondary3;
    position: absolute;
    opacity: 0;
    top: 0;
}

.linkContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 1;
    color: $navLinkTitle;

    @media (max-width: 800px) {
        display: grid;
        grid-template-columns: minmax(40px, 90px) 1fr;
    }
}

.linkTitle {
    @include text-15-regular;
    color: $navLinkTitle;
    opacity: 0;
    text-decoration: none;

    @media (max-width: 800px) {
        opacity: 1;
    }
}

.activeNavLink {
    .indicator {
        opacity: 1;
    }

    .linkTitle {
        opacity: 1;
        color: $secondary3;
    }

    svg path {
        fill: $secondary3;
    }
}

:global {
    .activeHomeNavLink {
        .station {
            .fill {
                fill: $secondary3;
            }

            .stroke {
                stroke: $secondary3;
                fill: none;
            }
        }
    }
}
