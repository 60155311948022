@import 'src/assets/styles/variables.module.scss';
@import 'src/assets/styles/fontMixins.scss';

.main {
    padding: 0px 20px;
    display: flex;
    background: $background2;
    border-radius: 1em;
    cursor: pointer;
    align-items: center;

    @media (max-width: 430px) {
        flex-direction: column;
        justify-content: center;
    }
}

.disable {
    background: $neutralDark;
    cursor: not-allowed;
}

.checkBoxContainer {
    padding-right: 20px;

    @media (max-width: 430px) {
        padding: 0;
    }
}

.titleText {
    color: $textColor;
    @include text-20-regular;

    @media (max-width: 870px) {
        text-align: center;
        width: 100%;
        display: flex;
    }
    @media (max-width: 430px) {
        margin-top: 10px;
    }
}

.disableTitleText {
    color: $primaryGray;
}

.infoContainer {
    flex: 1;
    display: flex;
    justify-content: flex-end;

    @media (max-width: 430px) {
        flex: initial;
    }
}
