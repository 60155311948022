@import 'src/assets/styles/fontMixins.scss';
@import 'src/assets/styles/variables.module.scss';
@import 'src/assets/styles/signTemplate.scss';

.backGround {
    position: fixed;
    z-index: 1300;
    right: 0px;
    bottom: 0px;
    top: 0px;
    left: 0px;
    align-items: center;
    justify-content: center;
    background-color: $modalBackground;
    display: flex;
}

.content {
    display: flex;
    flex-direction: column;
    box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2),
        0px 24px 38px 3px rgba(0, 0, 0, 0.14),
        0px 9px 46px 8px rgba(0, 0, 0, 0.12);
    border-radius: 4px;
    overflow: hidden;
    background: $neutralLight;
    max-width: 500px;

    @media (max-width: 800px) {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        max-width: 100%;
        max-height: 100%;
        width: 100%;
    }
}

.container {
    height: 100%;
    padding: 20px 0.75em;
    h4 {
        color: $textColor;
        @include text-17-medium;
        margin: 0;
        padding: 0;
    }
}

.card {
    box-shadow: 0px 20px 20px rgba(0, 0, 0, 0.2);
    border-radius: 1em;
    padding: 24px 28px 66px 28px;
    .grid {
        display: grid;
        grid-gap: 1em;
        grid-template-columns: min-content 1fr;

        p {
            padding: 0;
            margin: 0;
            color: $neutralMedium;
            @include text-15-regular;
        }
    }
}
