@import 'src/assets/styles/variables.module.scss';
@import 'src/assets/styles/fontMixins.scss';
.button {
    border: none;
    background: $defaultButtonGradient;
    border-radius: 1em;
    padding: 21px 0;
    color: $textColor2;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    @include text-20-regular;
    width: 10.75em;
    // box-shadow: $boxShadowPrimary2;
}
.md {
    width: 224px;
}
.outline {
    background: transparent;
    border: 1px solid $neutralDark;
    box-shadow: none;
    color: $textColor;
}

.white {
    color: $neutralLight;
    border: 1px solid $neutralLight;
}

.selected {
    border: 1px solid $neutralLight;
    background: $neutralLight;
    color: $activeButtonColor;
}

.outlineSm {
    background: transparent;
    box-shadow: none;
    padding: 9px 12.5px;
    width: auto;
    min-width: 224px;
    @include text-15-regular;
}
