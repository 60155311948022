@import 'src/assets/styles/variables.module.scss';
@import 'src/assets/styles/mixins.scss';
@import 'src/assets/styles/fontMixins.scss';

.main {
    position: relative;
    width: 100%;
    user-select: none;
    height: 56px;
}

.disabled{
    .selectedContainer {
        background-color: $diabledColor;
        box-shadow: none;
}
}
.childrenContainer {
    @include scroll;
    display: none;
    max-height: 279px;
    position: absolute;
    width: 100%;
    background: $neutralLight;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    border-radius: 1em;
    transform: translateY(6px);
    top: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    overflow-y: overlay;
    z-index: 20;
    border: 2px solid #4A5BF0;
    padding: 0.25rem;
}

.activeChildrenContainer {
    display: block;
}

.selectedContainer {
    background: $neutralLight;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    border-radius: 1em;
    color: $textColor;
   font-weight: 600;
font-size: 14px;
line-height: 140%;
    width: 100%;
    padding: 15px 20px;
    cursor: pointer;
    padding-right: 31px;
    height: 100%;
    display: flex;
    align-items: center;
}

.activeSelectedContainer {
    background: $neutralLight;
}

.selected {
    height: 22px;
    text-overflow: ellipsis;
    display: flex;
    align-items: center;
    color: $textColor;
    font-weight: 600;
}

.inputContainer {
    position: absolute;
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
}

.input {
    background: transparent !important;
    outline: none;
    color: $textColor;
   font-weight: 600;
font-size: 14px;
line-height: 140%;
    width: 100%;
    border: none;
    height: 100%;
    padding: 20px;
}

.cherDown {
    position: absolute;
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none;
    height: 24px;
}

.cherDownActive {
    transform: translateY(-50%) rotate(180deg);
}

.selectError {
    border: 2px solid;
    border-color: $errorColor;
}
