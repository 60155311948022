.positionContainer {
    position: relative;
    height: 1px;
    width: 1px;
}

.yourPosition {
    position: absolute;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: rgba(15, 79, 157, 1);
    box-shadow: 0 0 0 0 rgba(15, 79, 157, 1);
    animation: pulse-red 2s infinite;
    transform: scale(1);
    top: -10px;
    left: -10px;
}

@keyframes pulse-red {
    0% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(15, 79, 157, 0.7);
    }

    70% {
        transform: scale(1);
        box-shadow: 0 0 0 10px rgba(15, 79, 157, 0);
    }

    100% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(15, 79, 157, 0);
    }
}
