@import 'src/assets/styles/variables.module.scss';
@import 'src/assets/styles/fontMixins.scss';

.main {
    position: relative;
    width: 100%;
    user-select: none;
    label {
        position: absolute;
        z-index: 99;
        margin: 1rem 1.2rem;
        font-size: 12px;
        font-weight: 400;
        line-height: 15px;
        letter-spacing: 0em;
        text-align: left;
        color: $textColor;
    }
}

.input {
    background: $neutralLight;
    box-shadow: $boxShadowPrimary2;
    border-radius: 1em;
    padding: 20px;
    border: none;
    outline: none;
    @include text-17-regular;
    color: $textColor;
    width: 100%;
    height: 68px;
    font-weight: 600;
    --padding-top: 38px;
    --padding-end: 20px;
    --padding-bottom: 20px;
    --padding-start: 20px;
    --border-radius: 1em;
}

.error {
    border: 2px solid;
    border-color: $errorColor;
}

.dropDownContainer {
    display: block;
    max-height: 279px;
    position: absolute;
    width: 100%;
    background: $background2;
    box-shadow: $dropDownBoxShadow;
    border-radius: 1em;
    transform: translateY(3px);
    top: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    overflow-y: overlay;
    z-index: 3;
}

.loading {
    @include text-17-regular;
    color: $textColor;
    text-align: center;
    padding: 20px 0;
}

.item {
    padding: 10px 20px;
    @include text-17-regular;
    color: $textColor;
    width: 100%;
    cursor: pointer;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &:hover {
        background: $neutralLight;
    }
}

.errorInput {
    border: 2px solid;
    border-color: $errorColor;
}

.poweredByGoogleImg {
    display: flex;
    justify-content: flex-end;
    padding: 3px 5px;
    padding-top: 0;
}
