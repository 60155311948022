.main {
    overflow: hidden;
    position: relative;
    width: 100%;
}




.current {
    position: absolute;
    bottom: 50%;
    left: 50%;
    transform: translate(-50%);
}