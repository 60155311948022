@import 'src/assets/styles/variables.module.scss';
@import 'src/assets/styles/fontMixins.scss';

.main {
    // height: 100%;
    width: 100%;
    padding-bottom: 100%;
    border-radius: 50%;
    overflow: hidden;
    position: relative;
}

.current {
    height: 88%;
    width: 88%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
    color: $textColor2;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--bgColor);
    z-index: 5;
    border-radius: 50%;
    @include text-13-regular;
}

.circleColor {
    height: 100%;
    width: 100%;
    position: absolute;
    background-color: var(--circleColor);
    border-radius: 50%;
}

.left {
    height: 100%;
    width: calc(50% + 1px);
    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden;
    div {
        background: var(--progressColor);
        position: absolute;
        width: 200%;
        height: 100%;
        transform: rotate(180deg);
        left: -100%;
        transform-origin: right;
    }
}

.right {
    height: 100%;
    width: 50%;
    position: absolute;
    top: 0;
    right: 0;
    overflow: hidden;
    div {
        background: var(--progressColor);
        position: absolute;
        width: 200%;
        height: 100%;
        transform: rotate(180deg);
        right: -100%;
        transform-origin: left;
    }
}
