@import 'src/assets/styles/variables.module.scss';
@import 'src/assets/styles/mixins.scss';

.main {
    flex: 1;
    position: relative;
    display: flex;
    height: 100%;

    @media (max-width: $tablet) {
        flex-direction: column;
    }
}

.logoClassName {
    @media (max-width: $tablet) {
        background: $neutralLight;
        padding: 10px 28px;
        border-radius: 0px 0px 24px 24px;
        height: 5.5rem;
    }
}

.header {
    @media (max-width: $tablet) {
        position: absolute;
        justify-content: center;
    }
}

.closeButtonClassName {
    @media (max-width: $tablet) {
        position: absolute;
        right: 0;
    }
}

.footer {
    display: flex;
    padding: 0.75em;
    justify-content: flex-end;
}

.content {
    flex: 1;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;

    @media (max-width: $tablet) {
        grid-template-columns: 1fr;
        grid-template-rows: 1fr;
    }
}

.stepsContainer {
    background-color: $neutralLight;
    padding: 180px 93px;

    @media (max-width: $tablet) {
        display: none;
    }
}

.formContainerBg {
    background-color: $neutralLight;
    background: $primaryGradient1;
    box-shadow: 48px 48px 100px rgba(18, 61, 101, 0.3);
    display: flex;
    flex-direction: column;

    @media (max-width: $tablet) {
        border-radius: 0px;
    }
}

.formContainer {
    flex: 1;
    display: flex;

    justify-content: center;
    padding-top: 57px;

    @media (max-width: 800px) {
        padding-top: 80px;
    }
}
