@import 'src/assets/styles/variables.module.scss';
@import 'src/assets/styles/fontMixins.scss';

.input {
    border-radius: 1em;
    width: 100%;
    height: 56px;
    @include text-17-regular;
    color: $textColor;
    background: $neutralDark;
    padding: 20px;
    border-radius: 1em;
    border: none;
    box-shadow: $boxShadowPrimary2;

    --padding-top: 20px;
    --padding-end: 20px;
    --padding-bottom: 20px;
    --padding-start: 20px;
    --border-radius: 1em;

}

.main {
    max-height: 56px;
    position: relative;
}

.eye {
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translate(0, -50%);
    margin: 0;
    padding: 0;
    background: none;
    z-index: 10;
}

.password {
    padding-right: 50px;
}
