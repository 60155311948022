@import 'src/assets/styles/variables.module.scss';
@import 'src/assets/styles/fontMixins.scss';

.main {
    border-radius: 1em;
    background: $white;
    padding: 48px 36px;
    max-width: 340px;
    position: relative;
    margin: 26px auto 0 auto;
}

.check {
    position: absolute;
    background: $secondaryGradient;
    border-radius: 1em;
    height: 64px;
    width: 64px;
    top: 0;
    right: 0;
    transform: translate(40%, -40%);
    display: flex;
    img {
        margin: auto;
        height: 20px;
    }
}


.logo {
    p {
        text-align: center;
        @include text-17-regular;
        color: $textColor;
        margin: 0;
    }
}

.description {

    p {
        text-align: center;
        @include text-17-regular;
        color: $textColor;
        margin: 0;

        &:last-child {
            padding-bottom: 10px;
        }
    }
}

.car {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    span {
        @include text-20-bold;
        color: $textColor;
    }
    img {
        width: 70%;
        height: auto;
        margin: 2rem 0;

    }
}
.valueList{
    display: flex;
    justify-content: space-between;
    margin: 0;
}

.valueTitle {
    display: flex;
    align-items: center;
    color: $textColor;
    font-size: 0.85rem;
    font-weight: normal;
    max-height: 24px;
}

.value {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    color: $textColor;
    font-size: 0.85rem;
    font-weight: bold;
    max-height: 24px;
}
.capitalise {
    text-transform: capitalize;
}
