@import 'src/assets/styles/variables.module.scss';
@import 'src/assets/styles/fontMixins.scss';

.main {
    position: relative;
    width: 100%;
    user-select: none;
}

.input {
    background: $neutralDark;
    box-shadow: $boxShadowPrimary2;
    border-radius: 1em;
    padding: 20px;
    border: none;
    outline: none;
    color: $textColor;
    @include text-17-regular;
    width: 100%;
    height: 56px;
}

.error {
    border: 2px solid;
    border-color: $errorColor;
}

.dropDownContainer {
    display: block;
    max-height: 279px;
    position: absolute;
    width: 100%;
    background: $background2;
    box-shadow: $dropDownBoxShadow;
    border-radius: 1em;
    transform: translateY(3px);
    top: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    overflow-y: overlay;
    z-index: 2;
}

.loading {
    color: $textColor;
    @include text-17-regular;
    text-align: center;
    padding: 20px 0;
}

.item {
    padding: 10px 20px;
    color: $textColor;
    @include text-17-regular;
    width: 100%;
    cursor: pointer;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &:hover {
        background: $neutralLight;
    }
}

.errorInput {
    border: 2px solid;
    border-color: $errorColor;
}
