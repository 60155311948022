@import 'src/assets/styles/variables.module.scss';
@import 'src/assets/styles/fontMixins.scss';

.hiddenInput {
    position: absolute;
    z-index: -1;
    opacity: 0;
    width: 100%;
    left: 0;
}

:global {
    #ionicTimePicker {
        position: relative;
        border-radius: 1em;
        width: 100%;
        min-width: 5.5rem;
        height: 56px;
        color: $placeHolder;
        background: #fbfbfb;
        padding: 20px;
        border: none;
        box-shadow: $boxShadowPrimary2;
        -webkit-appearance: none;
        font-family: 'Montserrat';
        font-weight: 400;
        font-size: 1rem;
        opacity: 1;
        line-height: 100%;
        &.value {
            color: $textColor;
            font-weight: 400;
        }
    }
}
