@import 'src/assets/styles/variables.module.scss';
@import 'src/assets/styles/fontMixins.scss';

.rbc-calendar {
    width: 100%;
}

.rbc-month-header {
    margin-bottom: 17px;
}

.rbc-header {
    border: none;
    text-align: start;
    padding-left: 10px;

    span {
        color: $primaryGray;
        @include text-20-bold;

        @media (max-width: 800px) {
            font-size: 0.75rem;
        }
    }
}

.rbc-header + .rbc-header {
    border: none;
}

.rbc-month-view {
    border: none;
    display: grid;
    grid-template-rows: 40px repeat(6, auto);
}

.rbc-date-cell {
    text-align: start;
    padding: 10px;

    @media (max-width: 800px) {
        padding: 5px;
    }

    a {
        text-decoration: none;
        color: $textColor;
        pointer-events: none;
        @include text-20-regular;

        @media (max-width: 800px) {
            font-size: 0.75rem;
        }
    }
}

.rbc-day-bg + .rbc-today {
    background: $neutralLight;
    border: 3px solid $greenColor;
    border-radius: 0.75em;
}

.rbc-event.rbc-selected {
    background: none;
    outline: none;
}

.rbc-event {
    background: none;
}

.rbc-event-content {
    background: $secondaryGradient;
    border-radius: 8px;
    padding: 5px 10px;

    @include text-13-regular;

    @media (max-width: 800px) {
        padding: 3px;
    }
}

.rbc-day-bg {
    background: $neutralLight;
}

.rbc-off-range-bg {
    background: lightgrey;

    .rbc-event-content {
        background: rgba(30, 52, 102, 0.5);
    }
}

.rbc-month-row {
    &:nth-child(2) {
        .rbc-day-bg {
            &:first-child {
                border-radius: 1em 0 0 0;
            }

            &:last-child {
                border-radius: 0 1em 0 0;
            }
        }
    }

    &:last-child {
        .rbc-day-bg {
            &:first-child {
                border-radius: 0 0 0 1em;
            }

            &:last-child {
                border-radius: 0 0 1em 0;
            }
        }
    }
}

.rbc-overlay {
    padding: 20px;
    border-radius: 1em;

    .rbc-overlay-header {
        color: $textColor;
        @include text-17-medium;
    }

    @media (max-width: 800px) {
        max-width: 96%;
    }
}

.rbc-row-bg {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    grid-template-rows: 1fr;
    height: 100%;
}
