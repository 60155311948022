@import 'src/assets/styles/variables.module.scss';
@import 'src/assets/styles/fontMixins.scss';

.title {
    color: $neutralLight;
    @include h4-30-bold;
    margin: 0;
    padding: 0;
    text-align: center;
}
