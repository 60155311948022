@import 'src/assets/styles/fontMixins.scss';
@import 'src/assets/styles/variables.module.scss';

.backGround {
    position: fixed;
    z-index: 1300;
    right: 0px;
    bottom: 0px;
    top: 0px;
    left: 0px;
    align-items: center;
    justify-content: center;
    background-color: $modalBackground;
    display: flex;
}

.content {
    display: flex;
    max-height: calc(100% - 64px);
    flex-direction: column;
    box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2),
        0px 24px 38px 3px rgba(0, 0, 0, 0.14),
        0px 9px 46px 8px rgba(0, 0, 0, 0.12);
    border-radius: 4px;
    overflow: hidden;
    background: $neutralLight;
    max-width: 500px;

    @media (max-width: 800px) {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        max-width: 100%;
        max-height: 100%;
        width: 100%;
    }
}

.buttonContainer {
    background: $neutralLight;
    padding: 7px;
    display: grid;
    grid-gap: 1em;
    justify-content: center;
    grid-auto-flow: column;
}

.title {
    align-items: center;
    flex-direction: row;
    justify-content: center;

    h3 {
        color: $textColor;
        text-align: center;
        @include text-24-bold;
        margin-bottom: 1em;
    }
}

.text {
    display: flex;
    flex-direction: column;
    padding: 0 10px;
    margin-bottom: 40px;
    p {
        color: $textColor;
        font-family: 'Proxima';
        margin: 0;
        padding: 0;
        @include text-17-regular;
    }
}
