@import 'src/assets/styles/variables.module.scss';
@import 'src/assets/styles/fontMixins.scss';

.main {
    // max-width: 272px;
    // display: grid;
    // grid-template-rows: 1fr;
    // grid-template-columns: min-content 1fr min-content;
    // margin-bottom: 3rem;
}

.skipButton {
    padding: 0;
    color: $textColor2;
    @include text-17-regular;
    background: none;
    border: none;
    cursor: pointer;
    height: 60px;
    width: 30px;
    margin: 25px;
}

.nextButton {
    margin: 0;
    // padding-right: 16px;
    // margin-left: 30px;
    color: $textColor2;
    @include text-17-regular;
    background: none;
    border: none;
    cursor: pointer;
    // position: absolute;
    // top: 3%;
    // left: 95%;

    // @media (max-width: 800px) {
    //     top: 6%;
    //     left: 75%;
    // }

}

.circleContainer {
    display: grid;
    grid-auto-flow: column;
    align-items: center;
    grid-gap: 14px;
    position: absolute;
    left: 72%;
    bottom: 5%;

    @media (max-width: 800px) {
        left: 35%;
        bottom: 5%;
    }
    
    @media (max-width: 375px) {
        bottom: 10%;
        
    }
}

.circle {
    background: $primaryGray;
    width: 16px;
    height: 0.50em;
    border-radius: 5px;
}

.activeCircle {
    background: $neutralLight;
    width: 40px;

}

.whiteSkipButton {
    color: $textColor;
}

.whiteNextButton {
    color: $textColor3;
}
