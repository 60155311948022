@import 'src/assets/styles/variables.module.scss';

.checkBox {
    display: block;
    position: relative;
    width: 32px;
    height: 32px;
    cursor: pointer;
    border-radius: 50%;
    box-shadow: $boxShadowPrimary2;
}

.input {
    width: 100%;
    height: 100%;
    background-color: #006cff;
    position: absolute;
    opacity: 0;
    cursor: pointer;
    top: 0;
    left: 0;
}

.checkMark {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;

    display: flex;
    align-items: center;
    justify-content: center;

    background: $neutralDark;
    cursor: pointer;
    border-radius: 50%;

    box-shadow: $boxShadowPrimary2;
}

.mark {
    background: $neutralLight;
    height: 8px;
    width: 8px;
    border-radius: 50%;
    opacity: 0;
}

.checkBox input:checked ~ .checkMark {
    background: $secondaryGradient;
}

.checkBox input:checked ~ .checkMark .mark {
    opacity: 1;
}

.error {
    border: 2px solid;
    border-color: $errorColor;
}
