@import 'src/assets/styles/variables.module.scss';
@import 'src/assets/styles/mixins.scss';

.main {
    position: relative;
    margin: 0 auto;
    width: 100%;
}

.hiddenInput {
    position: absolute;
    z-index: -1;
    opacity: 0;
    width: 100%;
    left: 0;
    top: 0;
    height: 100%;
}

.input {
    -webkit-appearance: none;
    width: 100%;
    height: 11px;
    border-radius: 1em;
    outline: none;
    opacity: 1;
    -webkit-transition: 0.2s;
    transition: opacity 0.2s;
    margin: 0;
    padding: 0;
    background: transparent;

    &::-webkit-slider-thumb {
        -webkit-appearance: none;
        appearance: none;
        align-content: center;
        width: 22px;
        height: 22px;
        border-radius: 50%;
        cursor: pointer;
    }

    &::-moz-range-thumb {
        -webkit-appearance: none;
        appearance: none;
        align-content: center;
        width: 22px;
        height: 22px;
        border-radius: 50%;
        cursor: pointer;
    }

    &::-webkit-slider-runnable-track {
        background: transparent;
    }
}

.range {
    width: 100%;
    position: absolute;
    top: 4px;
    user-select: none;
    pointer-events: none;
}

.rangeSlider {
    height: 11px;
    border-radius: 1em;
    padding: 0 10px;
}

.rangeSliderThumb {
    width: 100%;
    height: 11px;
    border-radius: 1em;
    background:  #009F2D;
    position: relative;
}

.rangeSliderBackground {
    width: 100%;
    height: 11px;
    border-radius: 1em;
    background: #FBFBFB;
    margin-bottom: -11px;
    box-shadow: inset -2px -2px 4px rgba(208, 208, 209, 0.5), inset 2px 2px 4px #D0D0D1;
    border-radius: 8px;
}

.thumb {
    width: 28px;
    height: 28px;
    background: #009F2D;
    border: 4px solid $neutralLight;
    box-shadow: $boxShadowPrimary;
    border-radius: 44%;
    position: absolute;
    right: 0;
    transform: translateX(50%);
    top: -8px;
}
