@import 'src/assets/styles/variables.module.scss';
@import 'src/assets/styles/fontMixins.scss';

.main {
    max-width: 350px;
    height: 60px;
    position: relative;
}

.value {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: $textColor;
}

.input {
    background: $neutralDark;
    box-shadow: inset -3px -3px 7px rgba(255, 255, 255, 0.4),
        inset 3px 3px 7px rgba(136, 165, 191, 0.48);
    border-radius: 1em;
    display: grid;
    height: 100%;
    grid-template-rows: 1fr;
    grid-template-columns: 1fr min-content;
    align-items: center;
    padding-left: 15px;
    cursor: pointer;
    position: relative;
}

.hiddenInput {
    position: absolute;
    z-index: -1;
    opacity: 0;
}

.imgContainer {
    padding: 0 15px;
}

.placeholder {
    color: $placeHolder;
    @include text-17-regular;
}

.popup {
    position: absolute;
    z-index: 1;
    width: 400px;
    max-width: 400px;
    padding: 30px;
    background: $background;
    box-shadow: $boxShadow;
    border-radius: 1em;
    top: calc(100% + 20px);
    visibility: hidden;
    left: 50%;
    transform: translateX(-50%);

    @media (max-width: 800px) {
        position: fixed;
        overflow: auto;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        width: auto;
        transform: inherit;
        z-index: 2;
    }
}

.activePopup {
    visibility: initial;
}

.corner {
    position: absolute;
    display: flex;
    top: 0;
    right: 50%;
    transform: translate(50%, -100%) rotate(180deg);

    g {
        fill: $background;
    }
}

.title {
    margin: 0;
    margin-bottom: 22px;
    @include text-17-medium;
    color: $textColor;
}

.timersContainer {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 1em;
    margin-top: 1em;
}

.checkboxContainer {
    display: flex;
    margin-top: 1em;
    align-items: center;

    .fullDay {
        @include text-17-regular;
        color: $textColor;
        opacity: 0.8;
        margin-left: 20px;
    }
}

.selectContainer {
    margin-top: 1em;
}

.customInterval {
    margin-top: 1em;
}

.repeat {
    display: grid;
    grid-template-columns: 100px 1fr;
    grid-gap: 1em;
}

.footer {
    padding-top: 1em;
}

.cancel {
    border: 2px solid $primary3;
    background: transparent;
    color: $textColor;
    border-radius: 1em;
    padding: 17px 0;
    cursor: pointer;
    width: 110px;
    display: flex;
    align-items: center;
    justify-content: center;
    @include text-17-regular;
    grid-column-start: 2;
}

.apply {
    color: $textColor2;
    border-radius: 1em;
    padding: 17px 0;
    cursor: pointer;
    width: 110px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: $secondaryGradient;
    border: none;
    @include text-17-regular;
}

.footer {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-start: 3;
    grid-gap: 1em;
}

.end {
    margin-top: 1em;
}

.endItem {
    margin-top: 1em;
    display: grid;
    grid-template-columns: 40px 50px 1fr;
    grid-gap: 1em;

    align-items: center;

    .itemTitle {
        @include text-17-regular;
        color: $textColor;
        opacity: 0.8;
    }
}

.repeatDays {
    margin-top: 1em;
}

.selectMonth {
    margin-top: 1em;
}

.daysContainer {
    display: grid;
    grid-gap: 9px;
    grid-template-columns: repeat(7, 1fr);
}

.day {
    height: 36px;
    width: 36px;
    border: 2px solid $primaryGray;
    border-radius: 8px;
    background: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    span {
        color: $textColor;
        text-transform: uppercase;
        opacity: 0.7;
        @include text-13-medium;
    }
}

.activeDay {
    background: $secondaryGradient;
    border: none;

    span {
        color: $textColor2;
        opacity: 1;
    }
}

.errorInput {
    border: 2px solid;
    border-color: $errorColor;
}
