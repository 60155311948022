@import "src/assets/styles/variables.module.scss";
@import "src/assets/styles/mixins.scss";
@import 'src/assets/styles/fontMixins.scss';

.main {
    display: flex;
    align-items: center;
    text-decoration: none;
    color: $textColor;
    @include text-17-regular;
    
    img {
        margin-right: 13px;
    }
}
