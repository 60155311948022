@import 'src/assets/styles/variables.module.scss';

.checkBox {
    display: block;
    position: relative;
    width: 33px;
    height: 33px;
    cursor: pointer;
    border-radius: 0.75em;
}

.input {
    width: 100%;
    height: 100%;
    background-color: #006cff;
    position: absolute;
    opacity: 0;
    cursor: pointer;
    top: 0;
    left: 0;
}

.checkMark {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;

    border-radius: 0.75em;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;

    background: $neutralDark;
    cursor: pointer;
    border-radius: 0.75em;
    box-shadow: $boxShadowPrimary2;
}

.mark {
    color: $neutralLight;
    opacity: 0;
}

.checkBox input:checked ~ .checkMark {
    background: $secondaryGradient;
    box-shadow: none;
}

.checkBox input:checked ~ .checkMark .mark {
    opacity: 1;
}

.error {
    border: 2px solid;
    border-color: $errorColor;
}
