@import 'src/assets/styles/variables.module.scss';
@import 'src/assets/styles/mixins.scss';
@import 'src/assets/styles/fontMixins.scss';

.main {
    margin-top:128px;
    flex: 1 1;
    max-width: 405px;
    align-self: center;
    margin: 0 20px;
    width: 90%;
    padding-top: 5px;
    margin-bottom: 20px;
}

.contentTitle {
    @include text-24-bold;
    text-align: left;
    color: $textColor2;
    margin-top: 2rem;
}

.input {
    margin-bottom: 24px;
    text-transform: capitalize !important;
}

.warning {
    margin-bottom: 24px;
}

.text {
    @include text-13-regular;
    color: $textColor2;
    opacity: 0.7;
}

.termsAndPolicyContainer {
    display: flex;
    align-items: center;
}

.termsAndPolicyText {
    @include text-15-regular;
    color: $termsAndPolicyTextColor;
}

.termsAndPolicyLink {
    color: $textColor2;
    @include text-15-medium;
}

.checkBoxContainer {
    margin-right: 15px;
}

.submitButton {
    display: flex;
    justify-content: center;
    padding-top: 40px;
    margin-top: 80%;

    @media (max-width: 800px) {
        padding-top: 20px;
        margin-top: 75%;
    }

    @media (max-width: 375px) {
        padding-top: 10px;
        margin-top: 55%;
    }
}

.backButtonContainer {
    display: flex;
    justify-content: center;
}

.backButton {
    border: none;
    background: none;
    @include text-17-regular;

    color: $textColor2;
    text-transform: uppercase;
    cursor: pointer;
    margin: 4rem 0;
}
