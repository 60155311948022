@import 'src/assets/styles/mixins.scss';
@import 'src/assets/styles/fontMixins.scss';

.main {
    position: fixed;
    max-width: 370px;
    z-index: 100;
    right: 50px;
    bottom: 55px;
    transform: translateY(0px);
    animation-delay: 250ms;
    transition: all 0.3s;

    @media (max-width: $mobile) {
        width: 100%;
        max-width: 100%;
        right: 0 !important;
        bottom: 0 !important;
    }
}

.hidden {
    transform: translateY(200%);
}

.message {
    border-radius: 1em;
    box-shadow: 0px 4px 32px 2px $transparentBlack;

    @media (max-width: $mobile) {
        border-radius: 20px 20px 0 0;
    }
}

.contentContainer {
    display: flex;
    align-items: center;

    @media (max-width: 800px) {
        justify-content: space-between;
    }
}

.button {
    @include purpleButton;
}

.title {
    color: $textColor2;
    margin: 0;
    padding: 0;
    @include text-17-medium;
}

.iconContainer {
    display: flex;
    margin-right: 15px;
}

.icon {
    margin: a;
}

.content {
    color: rgb(255, 255, 255);
    text-overflow: ellipsis;
    overflow: auto;
    overflow: hidden;
}

.contentWrapper {
    overflow: hidden;
}
