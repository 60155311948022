@import 'src/assets/styles/variables.module.scss';
@import 'src/assets/styles/fontMixins.scss';

.main {
    position: relative;
    width: 100%;
    display: flex;
    margin-bottom: 9px;
}

.content {
    width: 100%;
    border-radius: 8px;
    background-color: $errorColor;
    display: flex;
    padding: 8px;
    position: relative;
    z-index: 2;
}

.text {
    @include text-13-regular;
    color: $textColor2;
    margin: 0;
    padding: 0;
}

.imageContainer {
    display: flex;
    align-items: center;
    padding: 0 10px;
}

.textContainer {
    display: flex;
    align-items: center;
}

.bubble {
    position: absolute;
    background-color: $errorColor;
    height: 20px;
    width: 20px;
    transform: rotate(45deg);
    bottom: -5px;
    left: 1em;
}
